import styled from "@emotion/styled";
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#275F79',
    maxWidth: 642,
    border: '1px solid #7FD2F7',
  },
}));

export { CustomToolTip}