import { ApiResources } from '../../Models/Api';
import AuditHistoryModal from '../../Components/AuditHistoryModal';
import type Contratante from '../../Models/Contratante';
import useAuditoriaCamposContratante from './useAuditoriaCamposContratante';

interface Props {
  contratante: Contratante;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function ContratanteAuditHistory({ contratante, fecharClicado, aberto }: Props) {
  const { getItensAlterados } = useAuditoriaCamposContratante();
  return (
    <AuditHistoryModal<Contratante>
      entityId={contratante.id}
      entityName="Contratante"
      apiResource={ApiResources.Contratante}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
      getItensAlterados(currentEntity, previousEntity)
      }
    />
  );
}