import { ApiResources } from '../../Models/Api';
import AuditHistoryModal from '../../Components/AuditHistoryModal';
import { AuditoriaCamposUsuario } from './AuditoriaCamposUsuario';
import type Usuario from '../../Models/Usuario';

interface Props {
  usuario: Usuario;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function UsuarioAuditHistory({ usuario, fecharClicado, aberto }: Props) {

  return (
    <AuditHistoryModal<Usuario>
      entityId={usuario.id}
      entityName="Usuário"
      apiResource={ApiResources.Usuario}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
        <AuditoriaCamposUsuario usuarioAtual={currentEntity} usuarioAnterior={previousEntity}/>
      }
    />
  );
}