import { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Pagination,
    useMediaQuery,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import { MoonLoader } from 'react-spinners';
import type { ApiResources } from '../../Models/Api';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { getTypeAuditoria } from '../../utils/typeRevision';
import { styles } from './styles';

interface AuditHistoryModalProps<T> {
    entityId: string | number;
    entityName: string;
    apiResource: ApiResources;
    open: boolean;
    onClose: () => void;
    getChangedItems: (currentEntity: T, previousEntity: T) => React.ReactNode;
}

export default function AuditHistoryModal<T>({
    entityId,
    entityName,
    apiResource,
    open,
    onClose,
    getChangedItems,
}: AuditHistoryModalProps<T>) {
    const [audits, setAudits] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { getService } = useGenericService();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (open) {
            setIsLoading(true);
            const loadAuditHistory = async () => {
                const service = getService(apiResource);

                try {
                    const response = await service.api.get(
                        `/auditoria/${entityId}`,
                        {
                            params: {
                                size: 2,
                                page: page - 1,
                            },
                        }
                    );

                    if (response.status === StatusRequisicao.OK) {
                        setAudits(response?.data?.content);
                        setTotalPages(response?.data?.totalPages);
                    } else {
                        enqueueSnackbar(
                            'Não foi possível encontrar o registro',
                            {
                                variant: 'error',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            };

            loadAuditHistory();
        }
    }, [open, entityId, enqueueSnackbar, page, apiResource]);

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };

    const renderAuditBody = () => {
        return audits.map((audit, index) => {
            if (index === 0 && page > 1) {
                return null;
            }

            return (
                <Card key={audit.id} sx={styles(isMobile).card}>
                    <CardContent>
                        <Alert
                            variant="outlined"
                            severity="info"
                            style={styles(audit.revision.revisionType).alert}
                            icon={false}
                        >
                            <div style={styles().divAuditoria}>
                                <div>
                                    <strong>Data da atualização:</strong>{' '}
                                    {format(
                                        parseISO(
                                            audit.revision.revisionDate.toString()
                                        ),
                                        'dd/MM/Y HH:mm',
                                        { locale: ptBR }
                                    ).toString()}{' '}
                                </div>
                                <div>
                                    <strong>Tipo de registro: </strong>
                                    {getTypeAuditoria(
                                        audit.revision.revisionType,
                                        audit.entidade
                                    )}
                                </div>
                            </div>
                            <strong> Usuário responsável:</strong>{' '}
                            {audit.revision.usuario.login}
                        </Alert>

                        {index > 0 &&
                            getChangedItems(
                                audit.entidade,
                                audits[index - 1].entidade
                            )}
                    </CardContent>
                </Card>
            );
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Histórico de modificações de {entityName}
            </DialogTitle>
            <Box
                sx={{
                    height: 564,
                    width: 564,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <DialogContent
                    sx={{
                        flex: '1 1 auto',
                        overflowY: 'auto',
                    }}
                >
                    {!isLoading && audits.length === 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <Alert severity="info">
                                Não há alterações registradas
                            </Alert>
                        </Box>
                    )}
                    {!isLoading && renderAuditBody()}
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <MoonLoader
                                color="#465f75"
                                loading={isLoading}
                                size={50}
                            />
                        </Box>
                    )}
                </DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Box>
            </Box>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
