import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Container, Divider, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    type ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';

import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PerfilUsuario from '../../Models/PerfilUsuario';
import type Usuario from '../../Models/Usuario';
import {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import type LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import { usePermission } from '../../hooks/usePermission';
import type { MensagemErroRequisicaoApi } from '../../Config/Api';
import GenericModalItensExcluidos from '../../Components/Modal/GenericModalItensExcluidos';
import { format } from 'date-fns';
import PerfilUsuarioAuditHistory from './PerfilUsuarioAuditHistory';

export default function Listar() {
    const [PerfilUsuarioMostrarAuditoria, setPerfilUsuarioMostrarAuditoria] =
        useState<PerfilUsuario>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [mostrarModalItensExcluidos, setMostrarModalItensExcluidos] =
        useState<boolean>(false);

    const navigate = useNavigate();

    const { isAllowed: isPermitidoExcluir } = usePermission(
        RecursosPerfisEnum.PERFIIS_USUARIOS_EXCLUIR
    );

    const { getService } = useGenericService();

    const [PerfilUsuarios, setPerfilUsuarios] = useState<PerfilUsuario[]>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        const loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const desativarPerfilUsuario = async (url: string) => {
        const servico = getService(ApiResources.PerfilUsuario);

        try {
            const resposta = await servico.api.delete(url);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Perfil de Usuario desativado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.PerfilUsuario);
            }
        } catch (error) {
            (error as MensagemErroRequisicaoApi[]).map((erro) =>
                enqueueSnackbar(`${erro.atributo}: ${erro.mensagem}`, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                })
            );
        }
    };

    const carregar = async () => {
        const servico = getService(ApiResources.PerfilUsuario);

        const resposta = await servico.api.get('');

        setPerfilUsuarios(resposta?.data?.content);
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip title="Ver" placement="top" disableInteractive>
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    navigate(
                                        `${FrontEndPathResources.PerfilUsuario}/ver/${row.id}`
                                    );
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.PERFIIS_USUARIOS_ALTERAR
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Alterar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(
                                            `${FrontEndPathResources.PerfilUsuario}/alterar/${row.id}`
                                        );
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.PERFIIS_USUARIOS_EXCLUIR
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Desativar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        desativarPerfilUsuario(`/${row.id}`);
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.PERFIIS_USUARIOS_CONSULTAR_HISTORICO
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        setPerfilUsuarioMostrarAuditoria(
                                            row as PerfilUsuario
                                        );
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                    </>
                );
            },
        },
    ];

    //#inicio - itens excluidos
    const validarRestaurar = async () => {
        return true;
    };

    const varColunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'dataHoraDeletado',
            label: 'Data da exclusão',
            id: 'dataHoraDeletado',
            align: 'left',
            width: 150,
            format: (row) =>
                format(new Date(row.dataHoraDeletado), 'dd/MM/Y').toString(),
        },
    ];
    //#fim - itens excluidos

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.PERFIIS_USUARIOS_VER}
            redirect={FrontEndPathResources.PerfilUsuario}
        >
            <Container maxWidth={false}>
                <PageTitle title="Perfil de Usuário" />

                <Divider />

                <GenericModalItensExcluidos
                    aberto={mostrarModalItensExcluidos}
                    fecharClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                    varNome="Perfis de Usuários"
                    varServico={ApiResources.PerfilUsuario}
                    varTipoClasse={typeof PerfilUsuario}
                    varColunas={varColunas}
                    validarRestaurar={validarRestaurar}
                />

                {mostrarModalAuditoria && PerfilUsuarioMostrarAuditoria && (
                    <PerfilUsuarioAuditHistory
                        key={'historico_alteracoes'}
                        perfilUsuario={PerfilUsuarioMostrarAuditoria}
                        aberto={mostrarModalAuditoria}
                        fecharClicado={() => {
                            setMostrarModalAuditoria(false);
                        }}
                    />
                )}

                <GenericComplexTable
                    service={getService(ApiResources.PerfilUsuario)}
                    columnToSearch={'nome'}
                    rows={PerfilUsuarios}
                    columns={colunas}
                    createButtonText="Cadastrar novo"
                    linkCreateButtonText={`${FrontEndPathResources.PerfilUsuario}/criar`}
                    mostrarIconeItensExcluidos={isPermitidoExcluir}
                    onIconeItensExcluidosClicado={() => {
                        setMostrarModalItensExcluidos(true);
                    }}
                />
            </Container>
        </PermissionGate>
    );
}
