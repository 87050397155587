import { ApiResources } from '../../Models/Api';
import AuditHistoryModal from '../../Components/AuditHistoryModal';
import useAuditoriaCamposPerfilUsuario from './useAuditoriaCamposPerfilUsuario';
import type PerfilUsuario from '../../Models/PerfilUsuario';

interface Props {
  perfilUsuario: PerfilUsuario;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function PerfilUsuarioAuditHistory({ perfilUsuario, fecharClicado, aberto }: Props) {
  const { getItensAlterados } = useAuditoriaCamposPerfilUsuario();

  return (
    <AuditHistoryModal<PerfilUsuario>
      entityId={perfilUsuario.id}
      entityName="Perfil Usuário"
      apiResource={ApiResources.PerfilUsuario}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
        getItensAlterados(currentEntity, previousEntity)
      }
    />
  );
}