import { Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { CamposFormulario } from './Configuracao';

export default function ADFormFields() {
    const {
        formState: { errors },
        control,
    } = useFormContext<CamposFormulario>();
    return (
        <Card variant='outlined' style={{marginBottom: 8, backgroundColor: "#FBFBFB"}}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="h6" component="h3">
                            Configurações de AD
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={12}>
                    <Controller
                        name="ldapDominio"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { value, onChange, onBlur, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <TextField
                                    {...field}
                                    
                                    margin="normal"
                                    value={value || ''}
                                    onChange={onChange}
                                    fullWidth
                                    label="Domínio"
                                    error={!!errors.ldapDominio}
                                    helperText={
                                        errors.ldapDominio
                                            ? errors.ldapDominio.message
                                            : ''
                                    }
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Controller
                        name="ldapIp"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { value, onBlur, onChange, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <TextField
                                    {...field}
                                    
                                    margin="normal"
                                    onChange={onChange}
                                    value={value || ''}
                                    fullWidth
                                    label="IP"
                                    error={!!errors.ldapIp}
                                    helperText={
                                        errors.ldapIp
                                            ? errors.ldapIp.message
                                            : ''
                                    }
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Controller
                        name="ldapPorta"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { value, onChange, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <TextField
                                    {...field}
                                    
                                    onChange={onChange}
                                    inputMode="decimal"
                                    margin="normal"
                                    fullWidth
                                    value={value || ''}
                                    label="Porta"
                                    error={!!errors.ldapPorta}
                                    helperText={
                                        errors.ldapPorta
                                            ? errors.ldapPorta.message
                                            : ''
                                    }
                                />
                            );
                        }}
                    />
                </Grid>
            </CardContent>
        </Card>
    );
}
