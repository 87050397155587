import CheckIcon from '@mui/icons-material/Check';
import DiscFullIcon from '@mui/icons-material/DiscFull';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    Box,
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../AuthProvider';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    type ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import { RecursosPerfisEnum, StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';

import ArticleIcon from '@mui/icons-material/Article';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import type { MensagemErroRequisicaoApi } from '../../Config/Api';
import Util from '../../Helpers/Util';
import { styles } from '../LotesPorTransportadora/helpers/Styles';
import type { FindAnexosDocumentosByOrdemColetaId } from '../OrdemColeta/domain/usecases/FindAnexosDocumentosByOrdemColetaId';
import type { PutArquivoOrdemColeta } from '../OrdemColeta/domain/usecases/PutArquivoOrdemColeta';
import { EStatusDoubleCheck } from './entities/EStatusDoubleCheck';
import { useFetchUseCase } from '../../hooks/useFetchUseCase';
import ModalDetalheErro from '../LotesPorTransportadora/pages/ModalDetalheErro';
import type { PostReprocessarOrdemColeta } from '../OrdemColeta/domain/usecases/PostReprocessarOrdemColeta';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import type { PostReprocessarDescarga } from '../OrdemColeta/domain/usecases/PostReprocessarDescarga';
import { EStatusOrdemColeta } from '../OrdemColeta/domain/entities/EStatusOrdemColeta';
import { EStatusDescarga } from './entities/EStatusDescarga';
import Search from '../../Components/Table/Search';
import { EStatusDoubleCheckDescarga } from './entities/EStatusDoubleCheckDescarga';
import { EFiltroStatus } from './entities/EFiltroStatus';
import { EStatusDoubleCheckEmbarque } from './entities/EStatusDoubleCheckEmbarque';
import type { IFindOrdemColetaDoubleCheckByFilter } from './dtos/IFindOrdemColetaDoubleCheckByFilter';
import type { IOrdemColetaResponseDto } from '../OrdemColeta/domain/dtos/IOrdemColetaResponseDto';
import type { FindOrdemColetaDoubleCheckByFiltros } from './usecases/FindOrdemColetaDoubleCheckByFiltros';
import type { IOrdemColeta } from '../OrdemColeta/domain/entities/IOrdemColeta';
import ModalAnexosDocumentos from '../LotesPorTransportadora/pages/components/ModalAnexosDocumentos';
import OrdemColetaLancamentoAuditHistory from './OrdemColetaLancamentoAuditHistory';
import { ModalHistoricoAlteracoesOrdemColeta } from '../LoteEmbarque/ModalHistoricoAlteracoesOrdemColeta';
import { getStatusDescargaColor, getStatusDoubleCheckColor } from '../LoteEmbarque/helpers/Styles';

interface Props {
    findOrdemColetasDoubleCheckByFiltros: FindOrdemColetaDoubleCheckByFiltros;
    putArquivoOrdemColeta: PutArquivoOrdemColeta;
    findAnexosDocumentosByOrdemColetaId: FindAnexosDocumentosByOrdemColetaId;
    postReprocessarOrdemColeta: PostReprocessarOrdemColeta;
    postReprocessarDescarga: PostReprocessarDescarga
}

export default function ListarDoubleCheck({
    findOrdemColetasDoubleCheckByFiltros,
    putArquivoOrdemColeta,
    findAnexosDocumentosByOrdemColetaId,
    postReprocessarOrdemColeta,
    postReprocessarDescarga
}: Props) {
    const { getService } = useGenericService();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [ordemColeta, setOrdemColeta] = useState<IOrdemColeta[]>();
    const [ordemColetaId, setOrdemColetaId] = useState<number>();
    const [dacteLiberaAdiantamento, setDacteLiberaAdiantamento] = useState<boolean>(false);
    const [quarteirizacao, setQuarteirizacao] = useState<boolean>(false);

    const { executePromise, loading } = useFetchUseCase();

    const [isReprocessando, setIsReprocessando] = useState(false);

    const [mostrarModalDetalheErro, setMostrarModalDetalheErro] =
        useState<boolean>(false);

    const [mostrarOrdemColetaDetalheErro, setMostrarOrdemColetaDetalheErro] =
        useState<IOrdemColetaResponseDto>();
    

    const [
        openDoubleCheckConfirmationModal,
        setOpenDoubleCheckConfirmationModal,
    ] = useState(false);

    const auth = useAuth();

    const [ordemColetaSelecionada, setOrdemColetaSelecionada] =
        useState<IOrdemColetaResponseDto>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [filtroStatus, setFiltroStatus] = useState("DOUBLE_CHECK_EMBARQUE");
    
    const [status, setStatus] = useState(null);

    const [rowsPerPageCustom, setRowsPerPageCustom] = useState(10);

    const [pageCustom, setPageCustom] = useState(0);

    const [totalCustom, setTotalCustom] = useState(0);

    const [sortCustom, setSortCustom] = useState('desc');

    const [textSearch, setTextSearch] = useState('');

    const [orderByCustom, setOrderByCustom] = useState('id');

    const [statusOptions, setStatusOptions] = useState(null);

    const AcoesOrdemColeta = ({ row }:{ row: IOrdemColetaResponseDto }) => {
        const [desabilitaDoubleCheckPrivate, setDesabilitaDoubleCheckPrivate] = useState(true);
    
        useEffect(() => {
            const checkDoubleCheck = async () => {
                const resultado = await desabilitaDoubleCheck(row);
                setDesabilitaDoubleCheckPrivate(resultado);
            };
            checkDoubleCheck();
        }, [row]);

        return (
            <>
                <PermissionGate recurso={RecursosPerfisEnum.ORDEM_COLETA_VER} redirect={''}>
                    <Tooltip title="Histórico de alterações" placement="top" disableInteractive>
                        <IconButton
                            aria-label="ver histórico de alterações"
                            onClick={() => {
                                setOrdemColetaSelecionada(row);
                                setMostrarModalAuditoria(true);
                            }}
                        >
                            <ArticleIcon />
                        </IconButton>
                    </Tooltip>
                </PermissionGate>
                <PermissionGate recurso={RecursosPerfisEnum.DOUBLE_CHECK_ALTERAR} redirect={''}>
                    <Tooltip title="Double check" placement="top" disableInteractive>
                        <IconButton
                            aria-label="Double check"
                            onClick={() => {
                                setOrdemColetaSelecionada(row);
                                setOpenDoubleCheckConfirmationModal(true);
                            }}
                            disabled={desabilitaDoubleCheckPrivate}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </PermissionGate>
                <PermissionGate recurso={RecursosPerfisEnum.DOUBLE_CHECK_ALTERAR} redirect={''}>
                    <Tooltip title="Double check da descarga" placement="top" disableInteractive>
                        <IconButton
                            aria-label="Double check da descarga"
                            onClick={() => {
                                setOrdemColetaSelecionada(row);
                                navigate(`${FrontEndPathResources.DoubleCheckDescarga}/${row.id}`);
                            }}
                            disabled={desabilitaDoubleCheckDescarga(row)}
                        >
                            <LocalShippingIcon color={desabilitaDoubleCheckDescarga(row) ? 'inherit' : 'warning'} />
                        </IconButton>
                    </Tooltip>
                </PermissionGate>
            </>
        );
    };



    const desabilitaDoubleCheck = async (row: any) => {
        const responsavelDoubleCheck =
            row.ordemColetaLancamento?.responsavelDoubleCheck;
        const usuarioLogado = auth.usuarioLogado?.login;
        const statusDoubleCheck = row.ordemColetaLancamento?.statusDoubleCheck;
        const statusOc = row.statusOrdemColeta;
        const regex = /EFETUA_PROCESSAMENTO ¥\[PROCESSA_TROCA\] ¥A nota fiscal \((\d+)\) série \((\d+)\) já foi lançada na empresa \((\d+)\) para o clifor \((\d+)\) no endereço \((\d+)\)\. Verifique o VFT003\.¢MPM=MAX025062¢/;

        if (statusOc === EStatusOrdemColeta.PENDENTE_DE_DESCARGA || statusOc === EStatusOrdemColeta.FINALIZADO || statusOc === EStatusOrdemColeta.FINALIZADO_GRE014) return true;
        if (
            statusDoubleCheck === EStatusDoubleCheck.PENDENTE ||
            statusDoubleCheck === EStatusDoubleCheck.LIBERADO
        ) {
            return true;
        }
        if (statusDoubleCheck === EStatusDoubleCheck.ERRO) {
            const service = getService(ApiResources.OrdemColetaMessageERP);
            const resposta = await service.api.get(`/${row.id}`,{ params: { messageType: 'ERROR' }});
            if (resposta.status === StatusRequisicao.OK) {
                return !resposta.data.some(d => regex.test(d.message));
            }
            return true
        }

        if (
            responsavelDoubleCheck === usuarioLogado ||
            !responsavelDoubleCheck
        ) {
            return false;
        }

        return true;
    };

    const desabilitaDoubleCheckDescarga = (row: any): boolean => {
        const status = row.ordemColetaValidacaoDescarga?.statusDescarga
        if (status === EStatusDescarga.NOVO_DESCARGA || status === EStatusDescarga.RESPONDIDO_DESCARGA) {
            return false;
        }
        return true;
    }

    function handleReprocessarOrdemColeta(
        ordemColeta: IOrdemColetaResponseDto
    ) {
        setMostrarOrdemColetaDetalheErro(ordemColeta);
        setMostrarModalDetalheErro(true);
    }

    const getStatusDoubleCheck = (statusOrdem: EStatusDoubleCheck | EStatusDescarga, ordemColeta: IOrdemColetaResponseDto) => {
        switch (statusOrdem) {
            case EStatusDoubleCheck.NOVO:
                return (
                    <Chip
                        style={{ 
                            ...styles().chipStatus, 
                            color: getStatusDoubleCheckColor(EStatusDoubleCheck.NOVO),
                            borderColor: getStatusDoubleCheckColor(EStatusDoubleCheck.NOVO)
                        }}
                        label={
                            <div style={styles().divChip}>
                                <ImportContactsIcon
                                    style={styles().iconStyle}
                                />{' '}
                                {EStatusDoubleCheck.NOVO}
                            </div>
                        }
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.RESPONDIDO:
                return (
                    <Chip
                        style={{ 
                            ...styles().chipStatus, 
                            color: getStatusDoubleCheckColor(EStatusDoubleCheck.RESPONDIDO),
                            borderColor: getStatusDoubleCheckColor(EStatusDoubleCheck.RESPONDIDO)
                        }}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.RESPONDIDO}
                            </div>
                        }
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.PENDENTE:
                return (
                    <Chip
                        style={{ 
                            ...styles().chipStatus, 
                            color: getStatusDoubleCheckColor(EStatusDoubleCheck.PENDENTE),
                            borderColor: getStatusDoubleCheckColor(EStatusDoubleCheck.PENDENTE)
                        }}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.PENDENTE}
                            </div>
                        }
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.ENVIADO_MAXYS:
                return (
                    <Chip
                        style={{ 
                            ...styles().chipStatus, 
                            color: getStatusDoubleCheckColor(EStatusDoubleCheck.ENVIADO_MAXYS),
                            borderColor: getStatusDoubleCheckColor(EStatusDoubleCheck.ENVIADO_MAXYS)
                        }}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.ENVIADO_MAXYS}
                            </div>
                        }
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.ENVIADO_MAXYS:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.ENVIADO_MAXYS}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.ATENDIMENTO:
                return (
                    <Chip
                        style={{ 
                            ...styles().chipStatus, 
                            color: getStatusDoubleCheckColor(EStatusDoubleCheck.ATENDIMENTO),
                            borderColor: getStatusDoubleCheckColor(EStatusDoubleCheck.ATENDIMENTO)
                        }}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.ATENDIMENTO}
                            </div>
                        }
                        variant="outlined"
                    />
                );
             case EStatusDoubleCheck.ERRO:
                return (
                    <Tooltip
                        title="Reprocessar Ordem de Coleta"
                        placement="top"
                        disableInteractive
                    >
                        <Chip
                            style={{ 
                                ...styles().chipStatus, 
                                color: getStatusDoubleCheckColor(EStatusDoubleCheck.ERRO),
                                borderColor: getStatusDoubleCheckColor(EStatusDoubleCheck.ERRO)
                            }}
                            aria-label="reprocessar-ordem-coleta"
                            onClick={() =>
                                handleReprocessarOrdemColeta(ordemColeta)
                            }
                            label={
                                <div style={styles().divChip}>
                                    <CancelIcon style={styles().iconStyle} />{' '}
                                    {EStatusDoubleCheck.ERRO}
                                </div>
                            }
                            variant="outlined"
                            disabled={isReprocessando}
                        />
                    </Tooltip>
                );
            
            case EStatusDoubleCheck.LIBERADO:
                return (
                    <Chip
                        style={{ 
                            ...styles().chipStatus, 
                            color: getStatusDoubleCheckColor(EStatusDoubleCheck.LIBERADO),
                            borderColor: getStatusDoubleCheckColor(EStatusDoubleCheck.LIBERADO)
                        }}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.LIBERADO}
                            </div>
                        }
                        variant="outlined"
                    />
                );
                case EStatusDescarga.NOVO_DESCARGA:
                    return (
                        <Chip
                            style={{ 
                                ...styles().chipStatus, 
                                color: getStatusDescargaColor(EStatusDescarga.NOVO_DESCARGA),
                                borderColor: getStatusDescargaColor(EStatusDescarga.NOVO_DESCARGA)
                            }}
                            label={
                                <div style={styles().divChip}>
                                    <ImportContactsIcon
                                        style={styles().iconStyle}
                                    />{' '}
                                    {EStatusDescarga.NOVO_DESCARGA}
                                </div>
                            }
                            variant="outlined"
                        />
                    );
                case EStatusDescarga.RESPONDIDO_DESCARGA:
                    return (
                        <Chip
                            style={{ 
                                ...styles().chipStatus, 
                                color: getStatusDescargaColor(EStatusDescarga.RESPONDIDO_DESCARGA),
                                borderColor: getStatusDescargaColor(EStatusDescarga.RESPONDIDO_DESCARGA)
                            }}
                            label={
                                <div style={styles().divChip}>
                                    <CheckIcon style={styles().iconStyle} />{' '}
                                    {EStatusDescarga.RESPONDIDO_DESCARGA}
                                </div>
                            }
                            variant="outlined"
                        />
                    );
                case EStatusDescarga.PENDENTE_DESCARGA:
                    return (
                        <Chip
                            style={{ 
                                ...styles().chipStatus, 
                                color: getStatusDescargaColor(EStatusDescarga.PENDENTE_DESCARGA),
                                borderColor: getStatusDescargaColor(EStatusDescarga.PENDENTE_DESCARGA)
                            }}
                            label={
                                <div style={styles().divChip}>
                                    <DiscFullIcon style={styles().iconStyle} />{' '}
                                    {EStatusDescarga.PENDENTE_DESCARGA}
                                </div>
                            }
                            variant="outlined"
                        />
                    );
                case EStatusDescarga.ATENDIMENTO_DESCARGA:
                    return (
                        <Chip
                            style={{ 
                                ...styles().chipStatus, 
                                color: getStatusDescargaColor(EStatusDescarga.ATENDIMENTO_DESCARGA),
                                borderColor: getStatusDescargaColor(EStatusDescarga.ATENDIMENTO_DESCARGA)
                            }}
                            label={
                                <div style={styles().divChip}>
                                    <DiscFullIcon style={styles().iconStyle} />{' '}
                                    {EStatusDescarga.ATENDIMENTO_DESCARGA}
                                </div>
                            }
                            variant="outlined"
                        />
                    );
                
                 case EStatusDescarga.ERRO_DESCARGA:
                    return (
                        <Tooltip
                            title="Reprocessar Ordem de Coleta"
                            placement="top"
                            disableInteractive
                        >
                            <Chip
                                style={{ 
                                    ...styles().chipStatus, 
                                    color: getStatusDescargaColor(EStatusDescarga.ERRO_DESCARGA),
                                    borderColor: getStatusDescargaColor(EStatusDescarga.ERRO_DESCARGA)
                                }}
                                aria-label="reprocessar-ordem-coleta"
                                onClick={() =>
                                    handleReprocessarOrdemColeta(ordemColeta)
                                }
                                label={
                                    <div style={styles().divChip}>
                                        <CancelIcon style={styles().iconStyle} />{' '}
                                        {EStatusDescarga.ERRO_DESCARGA}
                                    </div>
                                }
                                variant="outlined"
                                disabled={isReprocessando}
                            />
                        </Tooltip>
                    );
                
                case EStatusDescarga.LIBERADO_DESCARGA:
                    return (
                        <Chip
                            style={{ 
                                ...styles().chipStatus, 
                                color: getStatusDescargaColor(EStatusDescarga.LIBERADO_DESCARGA),
                                borderColor: getStatusDescargaColor(EStatusDescarga.LIBERADO_DESCARGA)
                            }}
                            label={
                                <div style={styles().divChip}>
                                    <CheckIcon style={styles().iconStyle} />{' '}
                                    {EStatusDescarga.LIBERADO_DESCARGA}
                                </div>
                            }
                            variant="outlined"
                        />
                    );
                
        }
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'Nº Ordem',
            id: 'id',
            minWidth: 40,
        },
        {
            attribute: 'cavalo.placa',
            label: 'Placa',
            id: 'cavalo.placa',
            minWidth: 40,
            format: (row) => row.cavalo.placa,
        },
        {
            attribute: 'localEmbarque',
            label: 'Embarque',
            id: 'localEmbarque',
            format: (row) =>
                row?.loteEmbarque?.localEmbarque?.descricao
                    ? row?.loteEmbarque?.localEmbarque?.descricao
                    : row?.loteEmbarque?.localEmbarque?.cidade,
            minWidth: 40,
        },
        {
            attribute: 'localDestino',
            label: 'Destino',
            id: 'localDestino',
            format: (row) =>
                row?.loteEmbarque?.localDestino?.descricao
                    ? row?.loteEmbarque?.localDestino?.descricao
                    : row?.loteEmbarque?.localDestino?.cidade,
            minWidth: 40,
        },
        {
            attribute: 'descricaoItem',
            label: 'Produto',
            id: 'descricaoItem',
            minWidth: 40,
        },
        {
            attribute: 'peso',
            label: 'Peso Planejado',
            id: 'peso',
            minWidth: 40,
            format: (row) => `${Util.formatNumberWithKG(row.peso)} KG`,
        },
        {
            attribute: 'status',
            label: 'Status',
            id: 'status',
            minWidth: 40,
            format: (row) =>
                getStatusDoubleCheck(
                    row.ordemColetaValidacaoDescarga ?
                    row.ordemColetaValidacaoDescarga.statusDescarga :
                    row.ordemColetaLancamento?.statusDoubleCheck
                        ? row.ordemColetaLancamento.statusDoubleCheck
                        : 'Liberado'
                    ,row
                ),
        },
        {
            attribute: 'tipoLoteEmbarque',
            label: 'Tipo',
            id: 'tipoLoteEmbarque',
            minWidth: 40,
            format: (row) =>
                row.tipoLoteEmbarque
                    ? row.tipoLoteEmbarque
                    : '',
        },
        {
            attribute: 'documentos',
            label: 'Anexos e documentos',
            id: 'documentos',
            format: (row) => {
                return (
                    <Tooltip
                        title="Abrir anexos e documentos"
                        placement="top"
                        disableInteractive
                    >
                        <IconButton
                            aria-label="abrir"
                            onClick={() => {
                                setOrdemColetaId(row.id);
                                if (row.loteEmbarque) {
                                    setQuarteirizacao(row.loteEmbarque.quarterizacao)
                                }
                                if (row.dacteLiberaAdiantamento == false) {
                                    setDacteLiberaAdiantamento(row.dacteLiberaAdiantamento)
                                }
                            }}
                        >
                            <PictureAsPdfIcon />
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => <AcoesOrdemColeta row={row} />,
        },
    ];

    const reprocessaOrdemColetaOuDescarga = (ordemColeta: IOrdemColetaResponseDto) => {
        if (ordemColeta.ordemColetaLancamento?.statusDoubleCheck === EStatusDoubleCheck.ERRO && ordemColeta.statusOrdemColeta === EStatusOrdemColeta.PENDENTE_DE_DESCARGA) {
            return (() => postReprocessarDescarga.execute(ordemColeta.id!))
        } 
        return (() => postReprocessarOrdemColeta.execute(ordemColeta.id!))
        
    }

    const reprocessarOrdemColeta = async (
        ordemColeta: IOrdemColetaResponseDto
    ) => {
        setIsReprocessando(true);
        executePromise(
            reprocessaOrdemColetaOuDescarga(ordemColeta),
            () => {
                enqueueSnackbar('Ordem de coleta reprocessada com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setIsReprocessando(false);
            },
            (errors) => {
                if (errors?.length > 0) {
                    errors.map((error: MensagemErroRequisicaoApi) =>
                        enqueueSnackbar(error?.mensagem, {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        })
                    );
                } else {
                    enqueueSnackbar(
                        'Não foi possível reprocessar a ordem de coleta',
                        {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        }
                    );
                }

                setIsReprocessando(false);
            }
        );
    };

    function handleStatusChange(event) {
		if (filtroStatus === "DOUBLE_CHECK_EMBARQUE") {
            setStatus(
			    event.target
				    .value as EStatusDoubleCheckEmbarque);
        }
        else if (filtroStatus === "DOUBLE_CHECK_DESCARGA") {
            setStatus(
                event.target
                    .value as EStatusDoubleCheckDescarga);
        }						   
    }

    const statusOptionsAux = (flow: EFiltroStatus) => {
        return Object.entries(
            flow === EFiltroStatus.DOUBLE_CHECK_EMBARQUE
                ? EStatusDoubleCheckEmbarque
                : EStatusDoubleCheckDescarga,
        ).map(([value, key]) => ({
            value: key,
            label: key,
        }));
    }

    useEffect(() => {
        executePromise(
            () => 
            findOrdemColetasDoubleCheckByFiltros.execute({
                page: pageCustom,
                size: rowsPerPageCustom,
                filtroStatus: filtroStatus,
                setTotal: setTotalCustom,
                sort: sortCustom,
                status:status,
                filtroTexto: textSearch,
                orderBy: orderByCustom
            }as IFindOrdemColetaDoubleCheckByFilter),
            (response) => {
                setTotalCustom(response?.data?.totalElements);
                setPageCustom(response?.data?.number);
				setOrdemColeta(response?.data?.content);
			},
            (errors) => {
                enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
            }
        )
    }, [status,textSearch,rowsPerPageCustom,pageCustom,sortCustom,orderByCustom])

    useEffect(() => {
        setStatus(null);
        const statusList = statusOptionsAux(EFiltroStatus[filtroStatus])
        setStatusOptions(statusList);
    }, [filtroStatus]);

    const flowOptions = Object.entries(EFiltroStatus).map(([value, key]) => ({
    value: value,
    label: key,
    }));

    return (
        <Container maxWidth={false}>
            <PageTitle title="Faturamento" />

            <Divider />
            <Grid container gap={2} pt={2}>
                <FormControl sx={{ width: 268 }}>
                    <InputLabel id="filtroStatus">Selecione o fluxo</InputLabel>
                    <Select
                        labelId="filtroStatus"
                        id="status"
                        value={filtroStatus}
                        label="Selecione o fluxo"
                        onChange={(event) => {
                            setFiltroStatus(
                                event.target.value as EFiltroStatus
                            )
                        }
                        }
                    >
                        {flowOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
             
                <FormControl sx={{ width: 250 }}>
                    <InputLabel id="labelStatus">Status</InputLabel>
                    <Select
                        labelId="labelStatus"
                        id="status"
                        value={status}
                        label="Status"
                        onChange={e => handleStatusChange(e)}
                    >
                        <MenuItem value={''}>
                            <em>Nenhum</em>
                        </MenuItem>
                        { statusOptions?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ maxWidth: 300, width: '100%' }}>
                    <Search
                        onSearch={(searchText) => {
                            setTextSearch(searchText);
                        }}
                    />
                </Box>
                 
                </Grid>
            <SpinnerLoading isLoading={loading}>
                <GenericComplexTable
                    columnToSearch={'id'}
                    service={getService(ApiResources.OrdemColetaDoubleCheck)}
                    rows={ordemColeta}
                    columns={colunas}
                    naoMostrarBotaoNovo
                    createButtonText="Cadastrar nova"
                    linkCreateButtonText={
                        `${FrontEndPathResources.OrdemColeta}/criar`
                    }
                    naoMostrarCampoPesquisar
                    setRowsPerPageCustom={setRowsPerPageCustom}
                    setPageCustom={setPageCustom}
                    totalCustom={totalCustom}
                    setSortCustom={setSortCustom}
                    setOrderByCustom={setOrderByCustom}

                    usarComponentesCustom={true}
                    />
            </SpinnerLoading>

            {mostrarModalAuditoria && ordemColetaSelecionada && (
                ordemColetaSelecionada?.ordemColetaLancamento ?
                (<OrdemColetaLancamentoAuditHistory
                    ordemColetaLancamento={ordemColetaSelecionada?.ordemColetaLancamento}
                    aberto={mostrarModalAuditoria}
                    fecharClicado={() => {
                        setMostrarModalAuditoria(false);
                    }}
                />) : 
                (<ModalHistoricoAlteracoesOrdemColeta
                    ordemColeta={ordemColetaSelecionada}
                    aberto={mostrarModalAuditoria}
                    fecharClicado={() => {
                        setMostrarModalAuditoria(false);
                    }}
                />)
            )}

            <Dialog
                open={openDoubleCheckConfirmationModal}
                onClose={() => setOpenDoubleCheckConfirmationModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {ordemColetaSelecionada?.ordemColetaLancamento
                        ?.responsavelDoubleCheck == null
                        ? 'Deseja iniciar processo de double check?'
                        : 'Você é o responsável pelo double check'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {ordemColetaSelecionada?.ordemColetaLancamento
                            ?.responsavelDoubleCheck == null
                            ? 'Você será atribuído como usuário responsável.'
                            : 'Deseja continuar?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={async () => {
                            try {
                                const servico = getService(
                                    ApiResources.OrdemColetaLancamento
                                );

                                await servico.api.post(
                                    `/alterar-responsavel-double-check/${
                                        ordemColetaSelecionada
                                            ?.ordemColetaLancamento?.id
                                    }/${auth.usuarioLogado?.login}`
                                );

                                navigate(
                                    `${FrontEndPathResources.DoubleCheck}/${ordemColetaSelecionada?.id}`
                                );
                            } catch (error) {
                                (error as MensagemErroRequisicaoApi[]).forEach(
                                    (erro) => {
                                        enqueueSnackbar(
                                            `${erro.atributo}: ${erro.mensagem}`,
                                            {
                                                variant: 'error',
                                                onClick: () => {
                                                    closeSnackbar();
                                                },
                                            }
                                        );
                                    }
                                );
                            } finally {
                                setOpenDoubleCheckConfirmationModal(false);
                            }
                        }}
                    >
                        Continuar
                    </Button>
                    <Button
                        onClick={() =>
                            setOpenDoubleCheckConfirmationModal(false)
                        }
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            {ordemColetaId && (
                <ModalAnexosDocumentos
                    open={!!ordemColetaId}
                    onClose={() => setOrdemColetaId(undefined)}
                    ordemColetaId={ordemColetaId}
                    dacteLiberaAdiantamento={dacteLiberaAdiantamento}
                    setDacteLiberaAdiantamento={setDacteLiberaAdiantamento}
                    quarteirizacao={quarteirizacao}
                    putArquivoOrdemColeta={putArquivoOrdemColeta}
                    findAnexosDocumentosByOrdemColetaId={
                        findAnexosDocumentosByOrdemColetaId
                    }
                />
            )}

            {mostrarModalDetalheErro && mostrarOrdemColetaDetalheErro && (
                    <ModalDetalheErro
                        ordemColeta={mostrarOrdemColetaDetalheErro}
                        aberto={mostrarModalDetalheErro}
                        fecharClicado={() => {
                            setMostrarModalDetalheErro(false);
                        }}
                        onReprocessa={() => {
                            reprocessarOrdemColeta(
                                mostrarOrdemColetaDetalheErro
                            );
                            setMostrarModalDetalheErro(false);
                        }}
                    />
                )}
        </Container>
    );
}
