import { ApiResources } from '../../Models/Api';
import AuditHistoryModal from '../../Components/AuditHistoryModal';
import type Produto from '../../Models/Produto';
import useAuditoriaCamposProduto from './useAuditoriaCamposProduto';

interface Props {
  produto: Produto;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function ProdutoAuditHistory({ produto, fecharClicado, aberto }: Props) {
  const { getItensAlterados } = useAuditoriaCamposProduto();

  return (
    <AuditHistoryModal<Produto>
      entityId={produto.id}
      entityName="Produto"
      apiResource={ApiResources.Produto}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
        getItensAlterados(currentEntity, previousEntity)
      }
    />
  );
}