import { ApiResources } from '../../Models/Api';
import AuditHistoryModal from '../../Components/AuditHistoryModal';
import useAuditoriaCamposOrdemColeta from './useAuditoriaCamposOrdemColeta';
import type { IOrdemColetaLancamento } from '../OrdemColeta/domain/dtos/IOrdemColetaLancamento';

interface Props {
  ordemColetaLancamento: IOrdemColetaLancamento;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function OrdemColetaLancamentoAuditHistory({ ordemColetaLancamento, fecharClicado, aberto }: Props) {
  const { getItensAlterados } = useAuditoriaCamposOrdemColeta();
  return (
    <AuditHistoryModal<IOrdemColetaLancamento>
      entityId={ordemColetaLancamento ? ordemColetaLancamento.id : ''}
      entityName="Ordem Coleta"
      apiResource={ApiResources.OrdemColetaLancamento}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
        getItensAlterados(currentEntity, previousEntity)
      }
    />
  );
}