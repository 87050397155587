import Joi from 'joi';

export const configuracaoValidation = Joi.object({
    email_envio: Joi.string()
        .max(100)
        .trim()
        .allow('', null)
        .optional()
        .messages({
            'string.max': 'Deve ter no máximo 150 caractereres',
        }),
    imagemBase64: Joi.string().trim().allow('', null).optional(),
    senha_email_envio: Joi.string().trim().allow('', null).optional(),
    provedor_email_contratante: Joi.string().allow('', null).optional(),
    host: Joi.string()
        .pattern(new RegExp(/([\w\-]+\.)+([\w\-]+\.)+[\w\-]{2,}$/))
        .message('host invalido'),
    port: Joi.number().allow('', null).optional(),
    autenticacaoAd: Joi.boolean().required(),
    ldapDominio: Joi.string()
        .when('autenticacaoAd', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.allow('', null),
        })
        .optional()
        .messages({
            'string.empty': 'O domínio deve ser informado',
        }),
    ldapIp: Joi.string()
        .when('autenticacaoAd', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.allow('', null),
        })
        .pattern(new RegExp(/^((\d{1,3}\.){3}\d{1,3}|([\w\-]+\.)+[\w\-]{2,})$/))
        .optional()
        .messages({
            'string.empty': 'O ip deve ser informado',
            'string.pattern.base':
                'O endereço IP deve ser informado no seguinte formato: xxx.xxx.xxx.xxx (ex: 192.168.1.1)',
        }),
    ldapPorta: Joi.string()
        .when('autenticacaoAd', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.allow('', null),
        })
        .pattern(/^[0-9]+$/)
        .optional()
        .messages({
            'string.empty': 'A porta deve ser informada',
            'string.pattern.base': 'A porta deve ser um número',
        }),
    validar_peso: Joi.boolean().required().messages({
            'any.required': 'O campo "Validar Peso" é obrigatório',
    }),
});
