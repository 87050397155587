import { Divider, List, ListItem, ListItemText } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import type Classificador from '../../Models/Classificador';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposClassificador() {
    const getItensAlterados = (
        classificadorAtual: Classificador,
        classificadorAnterior: Classificador
    ) => {
        return (
            <List sx={styles().listStyle} >
                {classificadorAtual.nome !==
                    classificadorAnterior.nome && (
                        <ListItem>
                            <ListItemText
                                primary="Nome"
                                secondary={
                                    <div style={styles().listItemText} >
                                        {classificadorAnterior.nome}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {classificadorAtual.nome}
                                    </div>
                                }
                            />
                        </ListItem>
                    )}

                {classificadorAtual.cpf !==
                    classificadorAnterior.cpf && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="CPF"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {classificadorAnterior.cpf}{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {classificadorAtual.cpf}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {classificadorAtual.cnpj !==
                    classificadorAnterior.cnpj && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="CNPJ"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {classificadorAnterior.cnpj}{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {classificadorAtual.cnpj}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {classificadorAnterior.usuario?.id !==
                    classificadorAtual.usuario?.id && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Usuário - ID"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {classificadorAnterior.usuario?.id}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {classificadorAtual.usuario?.id}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {classificadorAnterior.usuario?.login !==
                    classificadorAtual.usuario?.login && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Usuário - Login"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {classificadorAnterior.usuario?.login}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {classificadorAtual.usuario?.login}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {classificadorAnterior.usuario?.senha !==
                    classificadorAtual.usuario?.senha && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Usuário - Senha"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {classificadorAnterior.usuario?.senha}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {classificadorAtual.usuario?.senha}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {classificadorAnterior.usuario?.trocarSenha !==
                    classificadorAtual.usuario?.trocarSenha && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Usuário - Trocar Senha"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {String(classificadorAnterior.usuario?.trocarSenha)}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {String(classificadorAtual.usuario?.trocarSenha)}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {classificadorAnterior.contato?.email !==
                    classificadorAtual.contato?.email && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Email"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {classificadorAnterior.contato?.email}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {classificadorAtual.contato?.email}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {classificadorAnterior.contato?.telefone !==
                    classificadorAtual.contato?.telefone && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Telefone"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {classificadorAnterior.contato?.telefone}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {classificadorAtual.contato?.telefone}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {((classificadorAnterior.contato?.celular ?? '') !==
                    (classificadorAtual.contato?.celular ?? '')) && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Celular"
                                    secondary={
                                        <div style={styles().listItemText} >
                                            {classificadorAnterior.contato?.celular}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {classificadorAtual.contato?.celular}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}
            </List>
        );
    };

    return {
        getItensAlterados,
    };
}
