import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Pagination,
} from '@mui/material';
import type LoteEmbarqueTransportadora from '../../../Models/LoteEmbarqueTransportadora';
import { CorpoAuditoria } from './CorpoAuditoria';
import { useState } from 'react';

interface Props {
    loteEmbarqueTransportadora: LoteEmbarqueTransportadora;
    fecharClicado: () => void;
    aberto: boolean;
}

export interface OrdemColetaResumoDto {
    id: number;
    statusOrdemColeta: string;
    cadastradaViaPortal: boolean;
    numeroLoteGpe65: number;
    dataHoraColeta: string;
    placaVeiculo: string;
    descricaoItem: string;
    peso: number;
    usuarioResponsavelPelaDelecao: string;
}

export default function ModalHistoricoAlteracoesLoteEmbarque({
    loteEmbarqueTransportadora,
    fecharClicado,
    aberto = false,
}: Props) {
    const [totalPages, setTotalPages] = useState(0);

    const [page, setPage] = useState(1);

    function handlePageChange(
        event: React.ChangeEvent<unknown>,
        value: number
    ) {
        setPage(value);
    }

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Histórico de alterações do lote
            </DialogTitle>
            <Box
                sx={{
                    height: 564,
                    width: 564,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <DialogContent>
                    {loteEmbarqueTransportadora?.id &&
                        loteEmbarqueTransportadora.id && (
                            <CorpoAuditoria
                                loteEmbarqueTransportadoraId={
                                    loteEmbarqueTransportadora.id
                                }
                                setTotalPages={setTotalPages}
                                page={page}
                            />
                        )}
                </DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Box>
            </Box>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
