import {
    AccessTime,
    Check,
    CheckCircle,
    HourglassEmpty,
} from '@mui/icons-material';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

import {
    Card,
    CardContent,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { getStatusLotesPorTransportadoraColor, styles } from './helpers/Styles';
import { EStatusLotesPorTransportadora } from '../LotesPorTransportadora/domain/entities/EStatusLotesPorTransportadora';

export function TransportadorasVinculadas(row: any) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            tableHead: {
                backgroundColor: '#EDF0FE',
                fontWeight: 'bold',
            },
        })
    );
    const classes = useStyles();

    return (
        <Card style={styles().card}>
            <CardContent>
                <Typography
                    sx={{ fontSize: 20 }}
                    color="text.secondary"
                    gutterBottom
                >
                    Transportadoras vinculadas
                </Typography>

                <Table size="medium" aria-label="purchases">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                width={50}
                                className={classes?.tableHead}
                            >
                                N° Lote da Transp.
                            </TableCell>
                            <TableCell
                                width={50}
                                className={classes?.tableHead}
                            >
                                Transportadora
                            </TableCell>
                            <TableCell
                                width={120}
                                className={classes?.tableHead}
                            >
                                Peso Planejado Transp.
                            </TableCell>
                            <TableCell
                                width={200}
                                className={classes?.tableHead}
                            >
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getRowBodyExpandedRow(
                            row.row.loteEmbarqueTransportadoras!
                        )}
                    </TableBody>
                </Table>
            </CardContent>
            {!(
                row.row.loteEmbarqueTransportadoras &&
                row.row.loteEmbarqueTransportadoras.length > 0
            ) && (
                <Typography
                    sx={{ fontSize: 14, textAlign: 'center' }}
                    color="text.secondary"
                    gutterBottom
                    mb={3}
                >
                    Não há transportadoras vinculadas ao lote
                </Typography>
            )}
        </Card>
    );

    function getStatusOrdemFormatado(statusOrdem: EStatusLotesPorTransportadora) {
        switch (statusOrdem) {
            case EStatusLotesPorTransportadora.LIBERADO:
                return (
                    <Chip
                        style={{ 
                                ...styles().chipStatus, 
                                color: getStatusLotesPorTransportadoraColor(EStatusLotesPorTransportadora.LIBERADO),
                                borderColor: getStatusLotesPorTransportadoraColor(EStatusLotesPorTransportadora.LIBERADO)
                            }}
                        label={
                            <div style={styles().divChip}>
                                <Check style={styles().iconStyle} />{' '}
                                {EStatusLotesPorTransportadora.LIBERADO}
                            </div>
                        }
                        variant="outlined"
                    />
                );
            case EStatusLotesPorTransportadora.ANDAMENTO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={{ 
                                ...styles().chipStatus, 
                                color: getStatusLotesPorTransportadoraColor(EStatusLotesPorTransportadora.ANDAMENTO),
                                borderColor: getStatusLotesPorTransportadoraColor(EStatusLotesPorTransportadora.ANDAMENTO)
                            }}>
                                <HourglassEmpty style={styles().iconStyle} />{' '}
                                {EStatusLotesPorTransportadora.ANDAMENTO}
                            </div>
                        }
                        variant="outlined"
                    />
                );
            case EStatusLotesPorTransportadora.AGUARDANDO:
                return (
                    <Chip
                        style={{ 
                            ...styles().chipStatus, 
                            color: getStatusLotesPorTransportadoraColor(EStatusLotesPorTransportadora.AGUARDANDO),
                            borderColor: getStatusLotesPorTransportadoraColor(EStatusLotesPorTransportadora.AGUARDANDO)
                        }}
                        label={
                            <div style={styles().divChip}>
                                <AccessTime style={styles().iconStyle} />{' '}
                                {EStatusLotesPorTransportadora.AGUARDANDO}
                            </div>
                        }
                        variant="outlined"
                    />
                );
            case EStatusLotesPorTransportadora.FINALIZADO:
                return (
                    <Chip
                        style={{ 
                            ...styles().chipStatus, 
                            color: getStatusLotesPorTransportadoraColor(EStatusLotesPorTransportadora.FINALIZADO),
                            borderColor: getStatusLotesPorTransportadoraColor(EStatusLotesPorTransportadora.FINALIZADO)
                        }}
                        label={
                            <div style={styles().divChip}>
                                <ImportContactsIcon
                                    style={styles().iconStyle}
                                />{' '}
                                {EStatusLotesPorTransportadora.FINALIZADO}
                            </div>
                        }
                        variant="outlined"
                    />
                );
        }
    }

    function getRowBodyExpandedRow(
        loteEmbarqueTransportadora: any[]
    ): JSX.Element[] {
        let elements: JSX.Element[] = [];

        loteEmbarqueTransportadora?.forEach((letr) => {
            elements.push(
                <TableRow key={letr.id}>
                    <TableCell component="th" scope="row">
                        {letr.id}
                    </TableCell>
                    <TableCell align="left" component="th" scope="row">
                        {letr.transportadora.nome}
                    </TableCell>
                    <TableCell>
                        {letr.pesoAgendado
                            .toString()
                            .replace('.', ',')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        KG
                    </TableCell>
                    <TableCell>
                        {getStatusOrdemFormatado(letr.status)}
                    </TableCell>
                </TableRow>
            );
        });

        return elements;
    }
}
