import { format, parseISO } from 'date-fns';
import { RevisionType, type AuditoriaCustom } from '../../../Models/Auditoria';
import type LoteEmbarqueTransportadora from '../../../Models/LoteEmbarqueTransportadora';
import { ptBR } from 'date-fns/locale';
import {
    Alert,
    Card,
    CardContent,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { getTypeAuditoria } from '../utils/auditoriaTypes';
import type { ReactNode } from 'react';
import { styles } from '../helpers/Styles';
import { formatarPeso } from '../utils/formatadorPeso';

export function Auditoria({
    auditoria,
    index,
    page,
    children,
}: {
    auditoria: AuditoriaCustom<LoteEmbarqueTransportadora>;
    index: number;
    page: number;
    children: ReactNode;
}) {
    const isMobile = useMediaQuery('(max-width: 600px)');

    const revisionDateFormatted = format(
        parseISO(auditoria.revision.revisionDate.toString()),
        'dd/MM/yyyy HH:mm',
        {
            locale: ptBR,
        }
    );

    if(index === 0 && page > 1) {
        return <></>;
    }

    return (
        <Card key={index} sx={styles(isMobile).cardAlteracoes}>
            <CardContent>
                <Alert
                    variant="outlined"
                    severity="info"
                    style={
                        styles(auditoria.revision.revisionType).alertAlteracoes
                    }
                    icon={false}
                >
                    <div style={{ alignItems: 'center' }}>
                        <Typography>
                            <strong>Data da atualização:</strong>{' '}
                            {revisionDateFormatted}
                        </Typography>
                        <Typography>
                            <strong>Tipo de registro:</strong>{' '}
                            {getTypeAuditoria(auditoria.revision.revisionType)}
                        </Typography>
                    </div>
                    <strong>Usuário responsável:</strong>{' '}
                    {auditoria.revision.usuario.login}
                </Alert>
                {(index === 0 && page === 1) &&
                    auditoria.entidade?.ordens?.map((ordem) => {
                         const dataHoraFormatted = format(
                                parseISO(ordem.dataHoraColeta.toString()),
                                'dd/MM/yyyy HH:mm',
                                {
                                    locale: ptBR,
                                }
                        );
                        return (
                        <Card
                            key={ordem.id}
                            style={{ paddingBlock: 0, boxShadow: 'none' }}
                        >
                            <CardContent
                                style={{ paddingBlock: 0, boxShadow: 'none' }}
                            >
                                <Alert
                                    variant="outlined"
                                    severity="info"
                                    style={
                                        styles(ordem.statusOrdemColeta.toUpperCase() === 'CANCELADO' ? RevisionType.Deletado : auditoria.revision.revisionType)
                                            .alertAlteracoes
                                    }
                                    icon={false}
                                >
                                    <div style={{ alignItems: 'center' }}>
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography>
                                                    <strong>
                                                        Ordem coleta:
                                                    </strong>{' '}
                                                    {ordem.id}
                                                </Typography>
                                                <Typography>
                                                    <strong>Produto:</strong>{' '}
                                                    {ordem.descricaoItem}
                                                </Typography>
                                                <Typography>
                                                    <strong>Peso:</strong>{' '}
                                                    {formatarPeso(ordem.peso)}
                                                </Typography>
                                                <Typography>
                                                    <strong>Placa:</strong>{' '}
                                                    {ordem.placaVeiculo}
                                                </Typography>
                                                <Typography>
                                                    <strong>Status:</strong>{' '}
                                                    {ordem.statusOrdemColeta.toUpperCase()}
                                                </Typography>
                                                { ordem.statusOrdemColeta.toUpperCase() ===
                                                        'CANCELADO' &&
                                                    <Typography>
                                                        <strong>Usuário responsável :</strong>{' '}
                                                        {
                                                            ordem.usuarioResponsavelPelaDelecao}
                                                    </Typography>
                                                }
                                                <Typography>
                                                    <strong>
                                                        Data e Hora da Coleta:
                                                    </strong>{' '}
                                                    {dataHoraFormatted}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </Alert>
                            </CardContent>
                        </Card>
                    )
                    }
                    )}

                {children}
            </CardContent>
        </Card>
    );
}
