import type { OrdemColetaResumoDto } from '../Screens/LotesPorTransportadora/pages/ModalHistoricoAlteracoesLoteEmbarque';
import type { IOrdemColetaRequestDto } from '../Screens/OrdemColeta/domain/dtos/IOrdemColetaRequestDto';
import type Endereco from './Endereco';
import type Transportadora from './Transportadora';

export default class LoteEmbarqueTransportadora {
    constructor(
        public pesoPlanejado: number,
        public pesoAgendado: number,
        public saldo: number,
        public observacao: string,
        public status: string,
        public transportadora: Transportadora,
        public endereco: Endereco,
        public ordensColeta?: IOrdemColetaRequestDto[],
        public id?: number,
        public ordens?: OrdemColetaResumoDto[],
    ) {}
}
