import { NumericFormat } from 'react-number-format';
import type { TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';

export const CustomTextFieldNumeric = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            fullWidth
            InputProps={{
                endAdornment: 'KG',
                autoComplete: 'off',
            }}
        />
    );
};

type TProps = TextFieldProps & {
    label: string;
    onValuesChange: (val: number) => void;
};

export function InputKGWithMask({
    label,
    onValuesChange,
    defaultValue,
    type,
    variant,
    onChange,
    value,
    disabled,
    ...rest
}: TProps) {
    return (
        <NumericFormat
            defaultValue={0}
            label={label}
            thousandSeparator="." // Ponto como separador de milhar
            decimalSeparator=","
            decimalScale={0}
            valueIsNumericString
            onFocus={(e) => e.target.select()}
            onValueChange={(values: {
                floatValue?: number;
                formattedValue: string;
            }) => {
                // Verifique se o valor é um número antes de chamar onValuesChange
                if (values.floatValue !== undefined) {
                    onValuesChange(values.floatValue);
                }
            }}
            value={Number(value)}
            customInput={CustomTextFieldNumeric}
            disabled={disabled}
            {...rest}
        />
    );
}
