import { RevisionType } from '../Models/Auditoria';

export const getTypeAuditoria = (
    revisonType: RevisionType,
    entidade = null
) => {
    if (entidade.deletado) {
        return 'Exclusão';
    }

    switch (revisonType) {
        case RevisionType.Adicionado:
            return 'Criação';
        case RevisionType.Deletado:
            return 'Exclusão';
        case RevisionType.Modificado:
            return 'Modificação';
    }
};
