import { ApiResources } from '../../Models/Api';
import AuditHistoryModal from '../../Components/AuditHistoryModal';
import useAuditoriaCamposOrdem from './useAuditoriaCamposOrdem';
import type OrdemCarregamento from '../../Models/OrdemCarregamento';

interface Props {
  ordemCarregamento: OrdemCarregamento;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function OrdemAuditHistory({ ordemCarregamento, fecharClicado, aberto }: Props) {
  const { getItensAlterados } = useAuditoriaCamposOrdem();

  return (
    <AuditHistoryModal<OrdemCarregamento>
      entityId={ordemCarregamento.id}
      entityName="Ordem"
      apiResource={ApiResources.OrdemCarregamento}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
        getItensAlterados(currentEntity, previousEntity)
      }
    />
  );
}