import { RevisionType } from '../../../Models/Auditoria';
import { StyleSheet } from '../../../StyleSheet';
import { EStatusDescarga } from '../../DoubleCheck/entities/EStatusDescarga';
import { EStatusDoubleCheck } from '../../DoubleCheck/entities/EStatusDoubleCheck';
import { EStatusLotesPorTransportadora } from '../../LotesPorTransportadora/domain/entities/EStatusLotesPorTransportadora';
import { EStatusOrdemColeta } from '../../OrdemColeta/domain/entities/EStatusOrdemColeta';
import { EStatusLoteEmbarque } from '../domain/entities/EStatusLoteEmbarque';

const getBorderColorRevisionType = (revisionType: any) => {
    switch (revisionType) {
        case RevisionType.Adicionado:
            return '#3AB4F2';
        case RevisionType.Modificado:
            return '#FEB139';
        case RevisionType.Deletado:
            return '#D61C4E';
    }
};

export const styles = (props?: any) => {
    return StyleSheet.create({
        grid: {
            marginTop: 20,
            marginBottom: 20,
        },
        divider: { marginTop: 10, marginBottom: 15 },
        formularioFormControl: { marginTop: 20 },
        textField: { marginBottom: 20 },
        localClassificacaoControl: { marginBottom: 20 },
        card: { width: '100%' },
        gridListar: { marginBottom: 10 },
        chipStatus: { margin: 5 },
        iconStyle: { width: 13, height: 13 },
        button: {
            marginTop: 25,
            marginBottom: 15,
            backgroundColor: '#465f75',
        },
        divChip: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        cardAlteracoes: { marginTop: 2, width: props ? 280 : 500 },
        alertAlteracoes: {
            marginTop: 15,
            borderColor: getBorderColorRevisionType(props),
        },
        gridExcluidas: { marginTop: 20, marginBottom: 20 },
        dialogRomaneio: {
            width: 870,
            height: 800,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        divListItemText: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        listAuditoria: {
            width: '100%',
            maxWidth: 500,
            bgcolor: 'background.paper',
        },
        arrowForwardIcon: {
            marginLeft: 10,
            marginRight: 10,
        },
        divEmbarques: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        tableHead: {
            backgroundColor: '#EDF0FE',
            fontWeight: 'bold',
        },
    });
};

export const getStatusLoteEmbarqueColor = (eStatusLoteLoteEmbarqueType: any) => {
    switch (eStatusLoteLoteEmbarqueType) {
        case EStatusLoteEmbarque.ABERTO:
            return '#1976D2'; //cor:  #1976D2 - validada pelo cliente
        case EStatusLoteEmbarque.LIBERADO:
            return '#2E7D32'; //cor: #2E7D32 - validada pelo cliente
        case EStatusLoteEmbarque.ENCERRADO:
            return '#ED6C02'; //cor: #ED6C02 - validada pelo cliente
        case EStatusLoteEmbarque.SUSPENSO:
            return '#f83030'; //cor: #f83030 - TODO: COR NOVA - A VALIDAR COM O CLIENTE
        case EStatusLoteEmbarque.CANCELADO:
            return '#D32F2F'; //cor: #D32F2F - validada pelo cliente
    }
};

export const getStatusLotesPorTransportadoraColor = (eStatusLotesPorTransportadoraType: any) => {
    switch (eStatusLotesPorTransportadoraType) {
        case EStatusLotesPorTransportadora.ANDAMENTO:
            return '#1976D2'; //cor: #1976D2 - validada pelo cliente
        case EStatusLotesPorTransportadora.LIBERADO:
            return '#2E7D32'; //cor: #2E7D32 - validada pelo cliente
        case EStatusLotesPorTransportadora.AGUARDANDO:
            return '#ED6C02'; //cor: #ED6C02 - validada pelo cliente
        case EStatusLotesPorTransportadora.FINALIZADO:
            return '#9C27B0'; //cor: #9C27B0 - validada pelo cliente   
    }
};

export const getStatusOrdemColetaColor = (eStatusOrdemColetaType: any) => {
    switch (eStatusOrdemColetaType) {
        case EStatusOrdemColeta.AGENDADO:
            return '#1976D2'; //cor:  #1976D2 - validada pelo cliente
        case EStatusOrdemColeta.AGUARDANDO_GRE014:
            return '#133870'; //cor:  #133870 - validada pelo cliente
        case EStatusOrdemColeta.ENVIADO_MAXYS:
                return '#9C27B0'; //cor:  #9C27B0 - validada pelo cliente
        case EStatusOrdemColeta.FINALIZADO:
            return '#2E7D32'; //cor:  #2E7D32 - validada pelo cliente
        case EStatusOrdemColeta.FINALIZADO_GRE014:
            return '#73a976'; //cor:  #73a976 - TODO: COR NOVA - A VALIDAR COM O CLIENTE
        case EStatusOrdemColeta.ENVIADO_PARA_DOUBLECHECK:
            return '#B8B214'; //cor:  #B8B214 - TODO: COR NOVA - A VALIDAR COM O CLIENTE
        case EStatusOrdemColeta.PENDENTE_DE_DESCARGA:
            return '#FEB139'; //cor: #FEB139 - TODO: COR NOVA - A VALIDAR COM O CLIENTE
        case EStatusOrdemColeta.PENDENTE_DE_FATURAMENTO:
            return '#ED6C02'; //cor: #ED6C02 - validada pelo cliente
        case EStatusOrdemColeta.ERRO_TAF027:
            return '#f83030'; //cor: #f83030 - TODO: COR NOVA - A VALIDAR COM O CLIENTE
        case EStatusOrdemColeta.COM_ERRO:
            return '#D32F2F'; //cor: #D32F2F - validada pelo cliente
    }
};

export const getStatusDoubleCheckColor = (eStatusDoubleCheckType: any) => {
    switch (eStatusDoubleCheckType) {
        case EStatusDoubleCheck.ATENDIMENTO:
            return '#1976D2'; //cor:  #1976D2 - validada pelo cliente
        case EStatusDoubleCheck.NOVO:
            return '#133870'; //cor:  #133870 - validada pelo cliente
        case EStatusDoubleCheck.ENVIADO_MAXYS:
                return '#9C27B0'; //cor:  #9C27B0 - validada pelo cliente
        case EStatusDoubleCheck.LIBERADO: //LIBERADO_DESCARGA
            return '#2E7D32'; //cor:  #2E7D32 - validada pelo cliente
        case EStatusDoubleCheck.RESPONDIDO:
            return '#ED6C02'; //cor: #ED6C02 - validada pelo cliente
        case EStatusDoubleCheck.ERRO:
            return '#D32F2F'; //cor: #D32F2F - validada pelo cliente
        // * ver abaixo o getStatusDescargaColor para demais valores relativos ao processo de Descarga
        //case EStatusDoubleCheck.ATENDIMENTO_DESCARGA - valor de EStatusDescargaColor (ver getColor abaixo)
        //case EStatusDoubleCheck.NOVO_DESCARGA - valor de EStatusDescargaColor (ver getColor abaixo)
        //case EStatusDoubleCheck.LIBERADO_DESCARGA - valor de EStatusDescargaColor (ver getColor abaixo)
        //case EStatusDoubleCheck.PENDENTE_DESCARGA - valor de EStatusDescargaColor (ver getColor abaixo)
        //case EStatusDoubleCheck.RESPONDIDO_DESCARGA - valor de EStatusDescargaColor (ver getColor abaixo)
        //case EStatusDoubleCheck.ERRO_DESCARGA - valor de EStatusDescargaColor (ver getColor abaixo)
    }
};

export const getStatusDescargaColor = (eStatusDescargaType: any) => {
    switch (eStatusDescargaType) {
        case EStatusDescarga.ATENDIMENTO_DESCARGA:
            return '#1976D2'; //cor:  #1976D2 - validada pelo cliente    
        case EStatusDescarga.NOVO_DESCARGA:
            return '#133870'; //cor:  #133870 - validada pelo cliente
        case EStatusDescarga.LIBERADO_DESCARGA:
            return '#2E7D32'; //cor:  #2E7D32 - validada pelo cliente
        case EStatusDescarga.PENDENTE_DESCARGA:
            return '#FEB139'; //cor: #FEB139 - TODO: COR NOVA - A VALIDAR COM O CLIENTE
        case EStatusDoubleCheck.RESPONDIDO_DESCARGA:
            return '#ED6C02'; //cor: #ED6C02 - validada pelo cliente
        case EStatusDescarga.ERRO_DESCARGA:
            return '#D32F2F'; //cor: #D32F2F - validada pelo cliente
    }
};
