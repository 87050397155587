import { Container, Divider, Grid, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import type OrdemCarregamento from '../../Models/OrdemCarregamento';
import type Usuario from '../../Models/Usuario';
import {
    ChavesArmazenamentoStorage,
    StatusRequisicao,
} from '../../Models/Usuario';
import Formulario from './Formulario';
import useGenericService from '../../hooks/useGenericService';
import type LoginResponse from '../../Models/responses/LoginResponse';
import type LoteEmbarque from '../../Models/LoteEmbarque';

type Params = {
    id: string;
};

export default function Ver() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id } = useParams<Params>();
    const [ordem, setOrdem] = useState<OrdemCarregamento>();
    const [loteEmbarque, setLoteEmbarque] = useState<LoteEmbarque>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getService } = useGenericService();

    useEffect(() => {
        const loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const carregarOrdem = async () => {
            const servico = getService(ApiResources.LoteEmbarque);

            try {
                const resposta = await servico.api.get(`/${id}`);

                if (resposta.status === StatusRequisicao.OK) {
                    setLoteEmbarque(resposta.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            } catch (error) {
                setIsLoading(false);
            }
        };

        carregarOrdem();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, enqueueSnackbar]);

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={11}>
                        <PageTitle title={`Visualizando Lote Embarque ${loteEmbarque?.lote}`} />
                    </Grid>
                    {/* <Grid item xs={12} lg={1}>
                        <Tooltip
                            title="Histórico de alterações"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="ver"
                                onClick={() => {
                                    setMostrarModalAuditoria(true);
                                }}
                            >
                                <ArticleIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid> */}
                </Grid>
                <Divider />
                {/* {mostrarModalAuditoria && loteEmbarque && (
                    <ModalHistoricoAlteracoes
                        ordemCarregamento={loteEmbarque}
                        aberto={mostrarModalAuditoria}
                        fecharClicado={() => {
                            setMostrarModalAuditoria(false);
                        }}
                    />
                )} */}

                <Formulario disabled ordemCarregamento={loteEmbarque} />
            </Container>
        </SpinnerLoading>
    );
}
