import { ApiResources } from '../../Models/Api';
import AuditHistoryModal from '../../Components/AuditHistoryModal';
import useAuditoriaCamposClassificador from './useAuditoriaCamposClassificador';
import type Classificador from '../../Models/Classificador';

interface Props {
  classificador: Classificador;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function ClassificadorAuditHistory({ classificador, fecharClicado, aberto }: Props) {
  const { getItensAlterados } = useAuditoriaCamposClassificador();
  return (
    <AuditHistoryModal<Classificador>
      entityId={classificador.id}
      entityName="Classificador"
      apiResource={ApiResources.Classificador}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
      getItensAlterados(currentEntity, previousEntity)
      }
    />
  );
}