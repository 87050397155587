import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAuth } from '../../../AuthProvider';
import { IDoubleCheck, IUsuario } from '../entities/IDoubleCheck';
import { styles } from '../../PerfilUsuario/helpers/Styles';
import useGenericService from '../../../hooks/useGenericService';
import { MensagemErroRequisicaoApi } from '../../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../../Models/Api';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface UsuarioResponsavelProps {}

const UsuarioResponsavel: FunctionComponent<UsuarioResponsavelProps> = () => {
    const { control, getValues, setValue, reset } =
        useFormContext<IDoubleCheck>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    // const auth = useAuth();

    const { getService } = useGenericService();

    const navigate = useNavigate();

    const saveUsuarioResponsavel = async () => {
        try {
            const servico = getService(ApiResources.OrdemColetaLancamento);

            const ordemColetaLancamentoId = getValues(
                'ordemColetaLancamentoId'
            );

            const newUsuarioResponsavel = getValues('usuarioResponsavel')

            await servico.api.post(
                `/alterar-responsavel-double-check-edit-in/${ordemColetaLancamentoId}/${newUsuarioResponsavel?.login}`
            );

            navigate(FrontEndPathResources.DoubleCheck);
        } catch (error) {
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        } finally {
            setOpenConfirmModal(false);
        }
    };

    return (
        <>
            <Grid item lg={2} mt={2}>
                <Controller
                    name="usuarioResponsavel"
                    control={control}
                    // defaultValue={{
                    //     login: auth?.usuarioLogado?.login,
                    //     usuario: auth?.usuarioLogado?.perfilUsuario?.nome,
                    // }}
                    render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                    }) => (
                        <>
                            <Autocomplete
                                id="usuario-responsavel"
                                sx={{ width: 300 }}
                                options={
                                    getValues('listaUsuarios')
                                        ? getValues('listaUsuarios')
                                        : []
                                }
                                autoHighlight
                                getOptionLabel={(option) =>
                                    option.usuario ? option.usuario : ''
                                }
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {option.usuario}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Usuário"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                                onChange={(event, newValue: IUsuario | null) => {
                                    if (newValue)
                                    setValue('usuarioResponsavel', newValue);
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    option.login === value.login
                                }
                                // defaultValue={{
                                //     login: auth?.usuarioLogado?.login,
                                //     usuario:
                                //         auth?.usuarioLogado?.perfilUsuario?.nome,
                                // }}
                            />
                            <Grid item xs={12} lg={3}>
                                <Button
                                    onClick={() => {
                                        setOpenConfirmModal(true);
                                    }}
                                    variant="contained"
                                    style={styles().button}
                                    size="large"
                                >
                                    Alterar
                                </Button>
                            </Grid>
                            <Dialog
                                open={openConfirmModal}
                                onClose={() => setOpenConfirmModal(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {
                                        'Deseja alterar usuário responsável pelo double check?'
                                    }
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Você não poderá mais acessar a tela
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            saveUsuarioResponsavel();
                                            setOpenConfirmModal(false);
                                        }}
                                    >
                                        Salvar
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            setOpenConfirmModal(false)
                                        }
                                    >
                                        Fechar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )}
                />
            </Grid>
        </>
    );
};

export default UsuarioResponsavel;
