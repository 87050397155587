import { Alert, Box } from '@mui/material';
import type { AuditoriaCustom } from '../../../Models/Auditoria';
import type LoteEmbarqueTransportadora from '../../../Models/LoteEmbarqueTransportadora';
import { ItensAlterados } from './ItensAlterados';
import { useEffect, useState } from 'react';
import { StatusRequisicao } from '../../../Models/Usuario';
import { useSnackbar } from 'notistack';
import { ApiResources } from '../../../Models/Api';
import useGenericService from '../../../hooks/useGenericService';
import { Auditoria } from './Auditoria';
import { MoonLoader } from 'react-spinners';

export function CorpoAuditoria({
    loteEmbarqueTransportadoraId,
    page,
    setTotalPages,
}: {
    loteEmbarqueTransportadoraId: number;
    page: number;
    setTotalPages: (totalPages: number) => void;
}) {
    const [auditorias, setAuditorias] = useState<
        AuditoriaCustom<LoteEmbarqueTransportadora>[]
    >([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { getService } = useGenericService();

    useEffect(() => {
        const carregarAuditorias = async () => {
            setIsLoading(true);
            try {
                const resposta = await getService(
                    ApiResources.LoteEmbarquePorTransportadora
                ).api.get(`/auditoria/${loteEmbarqueTransportadoraId}`, {
                    params: {
                        size: 2,
                        page: page - 1,
                    },
                });

                if (resposta.status === StatusRequisicao.OK) {
                    setAuditorias(resposta?.data?.content);
                    setTotalPages(resposta?.data?.totalPages);
                } else {
                    mostrarErro('Não foi possível encontrar o registro');
                }
            } catch (error) {
                console.error(error);
                mostrarErro('Erro ao carregar auditorias');
            } finally {
                setIsLoading(false);
            }
        };

        carregarAuditorias();
    }, [page]);

    const mostrarErro = (mensagem: string) => {
        enqueueSnackbar(mensagem, {
            variant: 'error',
            onClick: () => closeSnackbar(),
        });
    };
    if (!isLoading && auditorias.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Alert severity="info">Não há alterações registradas</Alert>
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <MoonLoader color="#465f75" loading={isLoading} size={50} />
            </Box>
        );
    }

    return (
        <>
            {!isLoading &&
                auditorias.map((auditoria, index) => (
                    <Auditoria
                        key={auditoria.entidade.id}
                        auditoria={auditoria}
                        index={index}
                        page={page}
                    >
                        {index > 0 && (
                            <ItensAlterados
                                loteEmbarqueTransportadoraAnterior={
                                    auditorias[index - 1].entidade
                                }
                                loteEmbarqueTransportadoraAtual={
                                    auditoria.entidade
                                }
                            />
                        )}
                    </Auditoria>
                ))}
        </>
    );
}
