import { ApiResources } from '../../Models/Api';
import AuditHistoryModal from '../../Components/AuditHistoryModal';
import useAuditoriaCamposClienteFornecedor from './useAuditoriaCamposClienteFornecedor';
import type ClienteFornecedor from '../../Models/ClienteFornecedor';

interface Props {
  clienteFornecedor: ClienteFornecedor;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function ClienteFornecedorAuditHistory({ clienteFornecedor, fecharClicado, aberto }: Props) {
  const { getItensAlterados } = useAuditoriaCamposClienteFornecedor();
  return (
    <AuditHistoryModal<ClienteFornecedor>
      entityId={clienteFornecedor.id}
      entityName="Cliente Fornecedor"
      apiResource={ApiResources.ClienteFornecedor}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
      getItensAlterados(currentEntity, previousEntity)
      }
    />
  );
}