import { Grid, TextField } from '@mui/material';
import { Controller, useFormContext, useForm } from 'react-hook-form';
import InputKG from '../../../../../Components/Input/InputKG';
import InputPlaca from '../../../../../Components/Input/InputPlaca';
import { DatePicker } from '@mui/lab';
import { IOrdemColetaValidacaoDescarga } from '../../../domain/dtos/IOrdemColetaValidacaoDescarga';
import { InputKGWithMask } from '../../../../../Components/Input/InputKGWithMask';

export function ValidacaoDescargaForm() {
    const {
        formState: { errors },
        clearErrors,
        control,
    } = useFormContext<IOrdemColetaValidacaoDescarga>();

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="placaCaminhao"
                    control={control}
                    defaultValue={''}
                    rules={{ required: true }}
                    render={({
                        field: { ref, onChange, ...field },
                        fieldState,
                    }) => {
                        return (
                            <InputPlaca
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                margin="normal"
                                onChange={onChange}
                                fullWidth
                                label="Placa Caminhão"
                                error={fieldState?.invalid}
                                disabled
                                helperText={
                                    fieldState?.invalid && 
                                    'O campo não pode ficar em branco'
                                }
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="numeroNotaFiscal"
                    control={control}
                    defaultValue={''}
                    render={({ field: { ref, ...field }, fieldState }) => {
                        return (
                            <TextField
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                margin="normal"
                                fullWidth
                                disabled
                                label="Número Nota Fiscal"
                                error={fieldState?.invalid}
                                helperText={fieldState?.invalid && 'O campo não pode ficar em branco'}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="pesoOrigem"
                    control={control}
                    rules={{ required: true }}
                    render={({
                        field: { ref, onChange, ...field },
                        fieldState,
                    }) => {
                        return (
                            <InputKGWithMask
                                {...field}
                                margin="normal"
                                inputRef={ref}
                                required
                                value={field.value}
                                onValuesChange={onChange}
                                onChange={onChange}
                                disabled
                                fullWidth
                                label={'Peso Origem'}
                                error={fieldState?.invalid}
                                helperText={
                                    fieldState?.invalid && 'O campo não pode ficar em branco'
                                }
                                InputLabelProps={{
                                    shrink: !!field.value,
                                }}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="pesoDestino"
                    control={control}
                    rules={{ required: true }}
                    render={({
                        field: { ref, onChange, value, ...field },
                        fieldState,
                    }) => {
                        return (
                            <InputKGWithMask
                                {...field}
                                margin="normal"
                                required
                                inputRef={ref}
                                value={value}
                                onValuesChange={onChange}
                                onChange={onChange}
                                fullWidth
                                label={'Peso Destino'}
                                error={fieldState?.invalid}
                                helperText={
                                  fieldState?.invalid && 'O campo não pode ficar em branco'
                                }
                                InputLabelProps={{
                                    shrink: !!value,
                                }}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                <Controller
                    name="dataDescarga"
                    control={control}
                    defaultValue={''}
                    rules={{ required: true }}
                    render={({
                        field: { ref, ...field },
                        fieldState,
                    }) => {
                        return (
                            <DatePicker
                                {...field}
                                inputFormat="dd/MM/yyyy"
                                value={field.value}
                                onChange={field.onChange}
                                allowSameDateSelection
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        label="Data da descarga"
                                        placeholder=""
                                        required
                                        value={params.inputProps.value || ''}
                                        error={!!fieldState.error}
                                        helperText={
                                           fieldState?.invalid &&
                                            fieldState.error?.message
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
}
