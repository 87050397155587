import {
    Divider,
    List,
    ListItem,
    ListItemText,

} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import type Embarque from '../../Models/Embarque';
import { styles } from './helpers/Styles';
import type LoteEmbarque from '../../Models/LoteEmbarque';
import type LoteEmbarqueTransportadora from '../../Models/LoteEmbarqueTransportadora';

export default function useAuditoriaCamposOrdem() {

    function getEmbarquesAdicionados(embarquesAnteriores?: Embarque[], embarquesAtuais?: Embarque[]): Embarque[] {
        const embarquesA: Embarque[] = []
        if (embarquesAnteriores && embarquesAtuais) {
            embarquesAtuais.forEach(embarqueAtual => {
                if (embarquesAnteriores
                    .filter(embarqueAnterior => embarqueAnterior.id === embarqueAtual.id)
                    .length === 0) {

                    embarquesA.push(embarqueAtual)
                }
            })
        }
        return embarquesA;

    }

    function getTransportadorasAdicionadas(letAnteriores?: LoteEmbarqueTransportadora[], letAtuais?: LoteEmbarqueTransportadora[]): LoteEmbarqueTransportadora[] {
        const letA: LoteEmbarqueTransportadora[] = []
        if (letAnteriores && letAtuais) {
            letAtuais.forEach(letAtual => {
                if (letAnteriores
                    .filter(letAnterior => letAnterior.id === letAtual.id)
                    .length === 0) {

                    letA.push(letAtual)
                }
            })
        }
        return letA;
    }

    function getEmbarquesCancelados(embarquesAnteriores?: Embarque[],
        embarquesAtuais?: Embarque[]): Embarque[] {
        const embarquesC: Embarque[] = []

        if (embarquesAnteriores && embarquesAtuais) {
            embarquesAnteriores.forEach(embarqueAnterior => {
                if (embarquesAtuais
                    .filter(embarqueAtual => embarqueAnterior.id === embarqueAtual.id &&
                        embarqueAnterior.deletado !== embarqueAtual.deletado)
                    .length === 1) {
                    embarquesC.push(embarqueAnterior)
                }
            })
        }

        return embarquesC;

    }

    // function getTransportadorasCanceladas(letAnteriores?: LoteEmbarqueTransportadora[],letAtuais?: LoteEmbarqueTransportadora[]): LoteEmbarqueTransportadora[] {
    //     var letC: LoteEmbarqueTransportadora[] = []

    //     if (letAnteriores && letAtuais) {
    //         letAnteriores.forEach(letAnterior => {
    //             if (letAtuais
    //                 .filter(letAtual => letAnterior.id === letAtual.id &&
    //                     letAnterior.deletado !== letAtual.deletado)
    //                 .length === 1) {
    //                 letC.push(letAnterior)
    //             }
    //         })
    //     }

    //     return letC;
    // }

    const getItensAlterados = (
        loteEmbarqueAtual: LoteEmbarque,
        loteEmbarqueAnterior: LoteEmbarque
    ) => {

        return (
            <List sx={styles().listAuditoria}>
                {loteEmbarqueAtual.pesoPlanejado !==
                    loteEmbarqueAnterior.pesoPlanejado && (
                        <ListItem>
                            <ListItemText
                                primary="Peso planejado"
                                secondary={
                                    <div style={styles().divListItemText}>
                                        {loteEmbarqueAnterior.pesoPlanejado} KG{' '}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {loteEmbarqueAtual.pesoPlanejado} KG
                                    </div>
                                }
                            />
                        </ListItem>
                    )}

                {loteEmbarqueAtual.produto.id !==
                    loteEmbarqueAnterior.produto.id && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Produto"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {loteEmbarqueAnterior.produto.id} (
                                            {loteEmbarqueAnterior.produto.nome}
                                            ){' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {loteEmbarqueAtual.produto.id} (
                                            {loteEmbarqueAtual.produto.nome})
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.clifor.id !==
                    loteEmbarqueAtual.clifor.id && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Cliente/Fornecedor"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {loteEmbarqueAnterior.clifor.nome}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {loteEmbarqueAtual.clifor.nome}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {/* {loteEmbarqueAnterior.transportadora_id !==
                    loteEmbarqueAtual.transportadora_id &&
                    (loteEmbarqueAnterior.transportadora ||
                        loteEmbarqueAtual.transportadora) && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Transportadora"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {loteEmbarqueAnterior.transportadora
                                                ? loteEmbarqueAnterior
                                                    .transportadora.nome
                                                : ''}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {loteEmbarqueAtual.transportadora
                                                ? loteEmbarqueAtual
                                                    .transportadora.nome
                                                : ''}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )} */}

                {loteEmbarqueAnterior.codigoContrato !==
                    loteEmbarqueAtual.codigoContrato && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contrato"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior.codigoContrato
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {loteEmbarqueAtual.codigoContrato}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localClassificacao !==
                    loteEmbarqueAtual.localClassificacao && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Local da classificação"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior.localClassificacao
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual.localClassificacao
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.tipoNegociacao !==
                    loteEmbarqueAtual.tipoNegociacao && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Tipo de negociação"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior.tipoNegociacao
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {loteEmbarqueAtual.tipoNegociacao}{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localEmbarque.bairro !==
                    loteEmbarqueAtual.localEmbarque.bairro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Bairro"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localEmbarque.bairro
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localEmbarque.bairro
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localEmbarque.cidade !==
                    loteEmbarqueAtual.localEmbarque.cidade && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Cidade"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localEmbarque.cidade
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localEmbarque.cidade
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localEmbarque.cep !==
                    loteEmbarqueAtual.localEmbarque.cep && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - CEP"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localEmbarque.cep
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localEmbarque.cep
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localEmbarque.complemento !==
                    loteEmbarqueAtual.localEmbarque.complemento && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Complemento"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localEmbarque.complemento
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localEmbarque.complemento
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localEmbarque.estado !==
                    loteEmbarqueAtual.localEmbarque.estado && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Estado"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localEmbarque.estado
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localEmbarque.estado
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localEmbarque.logradouro !==
                    loteEmbarqueAtual.localEmbarque.logradouro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Logradouro"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localEmbarque.logradouro
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localEmbarque.logradouro
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localEmbarque.numero !==
                    loteEmbarqueAtual.localEmbarque.numero && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Número"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localEmbarque.numero
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localEmbarque.numero
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localDestino.bairro !==
                    loteEmbarqueAtual.localDestino.bairro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Bairro"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localDestino.bairro
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localDestino.bairro
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localDestino.cidade !==
                    loteEmbarqueAtual.localDestino.cidade && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Cidade"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localDestino.cidade
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localDestino.cidade
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localDestino.cep !==
                    loteEmbarqueAtual.localDestino.cep && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - CEP"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localDestino.cep
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localDestino.cep
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localDestino.complemento !==
                    loteEmbarqueAtual.localDestino.complemento && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Complemento"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localDestino
                                                    .complemento
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localDestino
                                                    .complemento
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localDestino.estado !==
                    loteEmbarqueAtual.localDestino.estado && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Estado"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localDestino.estado
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localDestino.estado
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localDestino.logradouro !==
                    loteEmbarqueAtual.localDestino.logradouro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Logradouro"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localDestino.logradouro
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localDestino.logradouro
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.localDestino.numero !==
                    loteEmbarqueAtual.localDestino.numero && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Número"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior
                                                    .localDestino.numero
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual
                                                    .localDestino.numero
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {loteEmbarqueAnterior.status !==
                    loteEmbarqueAtual.status && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Status da ordem"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                loteEmbarqueAnterior.status
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                loteEmbarqueAtual.status
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {/* {ordemCarregamentoAnterior.transportadora?.id !==
                    ordemCarregamentoAtual.transportadora?.id && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Transportadora"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .transportadora?.id
                                            }{' '}
                                            (
                                            {
                                                ordemCarregamentoAnterior
                                                    .transportadora?.nome
                                            }
                                            )
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual.transportadora?.id
                                            }{' '}
                                            (
                                            {
                                                ordemCarregamentoAtual.transportadora?.nome
                                            }
                                            )
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )} */}

                {getTransportadorasAdicionadas(loteEmbarqueAnterior.loteEmbarqueTransportadoras,
                    loteEmbarqueAtual.loteEmbarqueTransportadoras).map((e : LoteEmbarqueTransportadora) => {
                        return (
                            <>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary="Transportadora Adicionada"
                                        secondary={
                                            <div style={styles().divEmbarques}>
                                                {"Peso agendado: " + e.pesoAgendado + ", Transportadora:"}
                                                {e.transportadora !== null ? e.transportadora.nome !== null ? e.transportadora.nome : "" : ""}
                                                {/* {", Placa Veículo: "}
                                                {e.caminhao !== null ? e.caminhao.placaVeiculo !== null ? e.caminhao.placaVeiculo : "" : ""}
                                                {"\n"} */}
                                            </div>
                                        } />
                                </ListItem>
                            </>
                        )
                    })}

                {/* {getTransportadorasCanceladas(loteEmbarqueAnterior.loteEmbarqueTransportadoras,
                    loteEmbarqueAtual.loteEmbarqueTransportadoras).map(e => {
                        return (
                            <>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary="Embarque Cancelado"
                                        secondary={
                                            <div style={styles().divEmbarques}>
                                                {"Peso agendado: " + e.pesoAgendado + ", Transportadora:"}
                                                {e.transportadora !== null ? e.transportadora.nome !== null ? e.transportadora.nome : "" : ""}
                                                {", Placa Veículo: "}
                                                {e.caminhao !== null ? e.caminhao.placaVeiculo !== null ? e.caminhao.placaVeiculo : "" : ""}
                                                {"\n"}
                                            </div>
                                        } />
                                </ListItem>
                            </>
                        )
                })} */}

            </List >
        );
    };

    return {
        getItensAlterados,
    };
}
