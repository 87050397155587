import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { List, ListItem, ListItemText } from '@mui/material';
import { styles } from '../LotesPorTransportadora/helpers/Styles';
import type { IOrdemColetaLancamento } from '../OrdemColeta/domain/dtos/IOrdemColetaLancamento';
import Util from '../../Helpers/Util';

export default function useAuditoriaCamposOrdemColeta() {
    const getItensAlterados = (
        ocAtual: IOrdemColetaLancamento,
        ocAnterior: IOrdemColetaLancamento
    ) => {
        return (
            <List sx={styles().listAuditoria}>
                {ocAtual?.responsavelDoubleCheck !==
                    ocAnterior?.responsavelDoubleCheck && (
                    <ListItem key={`${ocAnterior?.id}-${ocAtual?.id}`}>
                        <ListItemText
                            primary="Responsável double check"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {ocAnterior?.responsavelDoubleCheck}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {ocAtual?.responsavelDoubleCheck}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ocAtual?.pesoBruto !== ocAnterior?.pesoBruto && (
                    <ListItem key={`${ocAnterior?.pesoBruto}-${ocAtual?.pesoBruto}`}>
                        <ListItemText
                            primary="Peso bruto"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {Util.formatNumberWithKG(
                                        ocAnterior?.pesoBruto
                                    )}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {Util.formatNumberWithKG(
                                        ocAtual?.pesoBruto
                                    )}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ocAtual?.pesoLiquido !== ocAnterior?.pesoLiquido && (
                    <ListItem key={`${ocAnterior?.pesoLiquido}-${ocAtual?.pesoLiquido}`}>
                        <ListItemText
                            primary="Peso liquido"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {Util.formatNumberWithKG(
                                        ocAnterior?.pesoLiquido
                                    )}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {Util.formatNumberWithKG(
                                        ocAtual?.pesoLiquido
                                    )}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ocAtual?.tara !== ocAnterior?.tara && (
                    <ListItem key={`${ocAnterior?.tara}-${ocAtual?.tara}`}>
                        <ListItemText
                            primary="Tara"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {Util.formatNumberWithKG(ocAnterior?.tara)}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {Util.formatNumberWithKG(ocAtual?.tara)}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ocAtual?.statusDoubleCheck !==
                    ocAnterior?.statusDoubleCheck && (
                    <ListItem key={`${ocAnterior?.statusDoubleCheck}-${ocAtual?.statusDoubleCheck}`}>
                        <ListItemText
                            primary="Status double check"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {ocAnterior?.statusDoubleCheck}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {ocAtual?.statusDoubleCheck}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ocAtual?.cte?.id !== ocAnterior?.cte?.id && (
                    <ListItem key={`${ocAnterior?.cte?.id} - ${ocAnterior?.cte?.nome}`}>
                        <ListItemText
                            primary="Cte"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {`${ocAnterior?.cte?.id} - ${ocAnterior?.cte?.nome}`}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {`${ocAtual?.cte?.id} - ${ocAtual?.cte?.nome}`}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ocAtual?.laudo?.id !== ocAnterior?.laudo?.id && (
                    <ListItem key={`${ocAnterior?.laudo?.id} - ${ocAnterior?.laudo?.nome}`}>
                        <ListItemText
                            primary="Laudo"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {`${ocAnterior?.laudo?.id} - ${ocAnterior?.laudo?.nome}`}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {`${ocAtual?.laudo?.id} - ${ocAtual?.laudo?.nome}`}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ocAtual?.mdfe?.id !== ocAnterior?.mdfe?.id && (
                    <ListItem key={`${ocAtual?.mdfe?.id} - ${ocAnterior?.mdfe?.id}`}>
                        <ListItemText
                            primary="Mdfe"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {`${ocAnterior?.mdfe?.id} - ${ocAnterior?.mdfe?.nome}`}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {`${ocAtual?.mdfe?.id} - ${ocAtual?.mdfe?.nome}`}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ocAtual?.ticketCaminhaoCarregado?.id !==
                    ocAnterior?.ticketCaminhaoCarregado?.id && (
                    <ListItem key={`${ocAtual?.ticketCaminhaoCarregado?.id } - ${ocAnterior?.ticketCaminhaoCarregado?.id}`}>
                        <ListItemText
                            primary="Ticket"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {`${ocAnterior?.ticketCaminhaoCarregado?.id} - ${ocAnterior?.ticketCaminhaoCarregado?.nome}`}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {`${ocAtual?.ticketCaminhaoCarregado?.id} - ${ocAtual?.ticketCaminhaoCarregado?.nome}`}
                                </div>
                            }
                        />
                    </ListItem>
                )}
            </List>
        );
    };

    return {
        getItensAlterados,
    };
}
