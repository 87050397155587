import { ApiResources } from '../../Models/Api';
import type Transportadora from '../../Models/Transportadora';
import useAuditoriaCamposTransportadora from './useAuditoriaCamposTransportadora';
import AuditHistoryModal from '../../Components/AuditHistoryModal';

interface Props {
  transportadora: Transportadora;
  fecharClicado: () => void;
  aberto: boolean;
}

export default function TransportadoraAuditHistory({ transportadora, fecharClicado, aberto }: Props) {
  const { getItensAlterados } = useAuditoriaCamposTransportadora();

  return (
    <AuditHistoryModal<Transportadora>
      entityId={transportadora.id}
      entityName="Transportadora"
      apiResource={ApiResources.Transportadora}
      open={aberto}
      onClose={fecharClicado}
      getChangedItems={(currentEntity, previousEntity) => 
        getItensAlterados(currentEntity, previousEntity)
      }
    />
  );
}