import { Divider, List, ListItem, ListItemText } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import type Transportadora from '../../Models/Transportadora';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposTransportadora() {
    const getItensAlterados = (
        transportadoraAtual: Transportadora,
        transportadoraAnterior: Transportadora
    ) => {
        // console.log("transportadoraAnterior")
        // console.log(transportadoraAnterior)
        // console.log("transportadoraAtual")
        // console.log(transportadoraAtual)
        // const isEmpty = (value) => value === null || value === '';

        return (
            <List sx={styles().list}>
                {transportadoraAtual.nome !==
                    transportadoraAnterior.nome && (
                        <ListItem>
                            <ListItemText
                                primary="Nome"
                                secondary={
                                    <div style={styles().div}>
                                        {transportadoraAnterior.nome}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {transportadoraAtual.nome}
                                    </div>
                                }
                            />
                        </ListItem>
                    )}

                {transportadoraAtual.nomeFantasia !==
                    transportadoraAnterior.nomeFantasia && (
                        <ListItem>
                            <ListItemText
                                primary="Nome Fantasia"
                                secondary={
                                    <div style={styles().div}>
                                        {transportadoraAnterior.nomeFantasia}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {transportadoraAtual.nomeFantasia}
                                    </div>
                                }
                            />
                        </ListItem>
                    )}

                {transportadoraAtual.cnpj !==
                    transportadoraAnterior.cnpj && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="CNPJ"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.cnpj}{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.cnpj}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {((transportadoraAnterior.contato?.email ?? "") !==
                    (transportadoraAtual.contato?.email ?? "")) && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Email"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.contato?.email}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.contato?.email}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {((transportadoraAnterior.contato?.telefone ?? "") !==
                    (transportadoraAtual.contato?.telefone ?? "")) && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Telefone"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.contato?.telefone}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.contato?.telefone}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {(transportadoraAnterior.contato?.celular ?? "") !==
				(transportadoraAtual.contato?.celular ?? "") && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Celular"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.contato?.celular}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.contato?.celular}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.cep !==
                    transportadoraAtual.endereco?.cep && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereco - CEP"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.cep}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.cep}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.logradouro !==
                    transportadoraAtual.endereco?.logradouro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereco - Logradouro"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.logradouro}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.logradouro}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.bairro !==
                    transportadoraAtual.endereco?.bairro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereco - Bairro"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.bairro}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.bairro}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {((transportadoraAnterior.endereco?.numero ?? '') !== (transportadoraAtual.endereco?.numero ?? '')) && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereco - Numero"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.numero}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.numero}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.complemento !==
                    transportadoraAtual.endereco?.complemento && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereco - Complemento"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.complemento}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.complemento}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.cidade !==
                    transportadoraAtual.endereco?.cidade && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereco - Cidade"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.cidade}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.cidade}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.estado !==
                    transportadoraAtual.endereco?.estado && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereco - Estado"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.estado}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.estado}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.ibge !==
                    transportadoraAtual.endereco?.ibge && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereco - IBGE"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.ibge}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.ibge}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.inscricaoMunicipal !==
                    transportadoraAtual.endereco?.inscricaoMunicipal && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Inscrição Municipal"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.inscricaoMunicipal}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.inscricaoMunicipal}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {transportadoraAnterior.endereco?.inscricaoEstadual !==
                    transportadoraAtual.endereco?.inscricaoEstadual && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Inscrição Estadual"
                                    secondary={
                                        <div style={styles().div}>
                                            {transportadoraAnterior.endereco?.inscricaoEstadual}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {transportadoraAtual.endereco?.inscricaoEstadual}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}
            </List>
        );
    };

    return {
        getItensAlterados,
    };
}
