import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Pagination,
    useMediaQuery,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ApiResources } from '../../Models/Api';
import type Auditoria from '../../Models/Auditoria';
import { RevisionType } from '../../Models/Auditoria';
import type LoteEmbarque from '../../Models/LoteEmbarque';
import type OrdemCarregamento from '../../Models/OrdemCarregamento';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';
import useAuditoriaCamposOrdem from './useAuditoriaCamposOrdem';
import { MoonLoader } from 'react-spinners';

interface Props {
    ordemCarregamento: OrdemCarregamento;
    fecharClicado: () => void;
    aberto: boolean;
}

export default function ModalHistoricoAlteracoesLoteEmbarque({
    ordemCarregamento,
    fecharClicado,
    aberto = false,
}: Props) {
    const [auditorias, setAuditorias] = useState<Auditoria[]>([]);

    const [totalPages, setTotalPages] = useState(0);

    const [page, setPage] = useState(1);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { getService } = useGenericService();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getItensAlterados } = useAuditoriaCamposOrdem();

    function handlePageChange(
        event: React.ChangeEvent<unknown>,
        value: number
    ) {
        setPage(value);
    }

    useEffect(() => {
        if (aberto) {
            setIsLoading(true);
            const carregarOrdem = async () => {
                const service = getService(ApiResources.LoteEmbarque);

                try {
                    const resposta = await service.api.get(
                        `/auditoria/${ordemCarregamento.id}`,
                        {
                            params: {
                                size: 2,
                                page: page - 1,
                            },
                        }
                    );

                    if (resposta.status === StatusRequisicao.OK) {
                        setAuditorias(resposta.data.content);
                        setTotalPages(resposta?.data?.totalPages);
                    } else {
                        enqueueSnackbar(
                            'Não foi possível encontrar o registro',
                            {
                                variant: 'error',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setIsLoading(false);
                }
            };

            carregarOrdem();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto, ordemCarregamento, enqueueSnackbar, page]);

    const getTypeAuditoria = (revisonType: RevisionType) => {
        switch (revisonType) {
            case RevisionType.Adicionado:
                return 'Criação';
            case RevisionType.Deletado:
                return 'Exclusão';
            case RevisionType.Modificado:
                return 'Modificação';
        }
    };

    const getCorpoAuditorias = () => {
        const elements: React.ReactElement[] = [];

        auditorias.forEach((auditoria, index) => {
            if (index === 0 && page > 1) {
                return;
            }

            elements.push(
                <Card sx={styles(isMobile).cardAlteracoes}>
                    <CardContent>
                        <Alert
                            variant="outlined"
                            severity="info"
                            style={
                                styles(auditoria.revision.revisionType)
                                    .alertAlteracoes
                            }
                            icon={false}
                        >
                            <div style={{ alignItems: 'center' }}>
                                <div>
                                    <strong>Data da atualização:</strong>{' '}
                                    {format(
                                        parseISO(
                                            auditoria.revision.revisionDate.toString()
                                        ),
                                        'dd/MM/Y HH:mm',
                                        { locale: ptBR }
                                    ).toString()}{' '}
                                </div>
                                <div>
                                    <strong>Tipo de registro: </strong>
                                    {getTypeAuditoria(
                                        auditoria.revision.revisionType
                                    )}
                                </div>
                            </div>
                            <strong> Usuário responsável:</strong>{' '}
                            {auditoria.revision.usuario.login}
                        </Alert>

                        {index > 0 &&
                            getItensAlterados(
                                auditoria.entidade as LoteEmbarque,
                                auditorias[index - 1].entidade as LoteEmbarque
                            )}
                    </CardContent>
                </Card>
            );
        });

        return elements;
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Histórico de alterações do lote embarque
            </DialogTitle>

            <Box
                sx={{
                    height: 564,
                    width: 564,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <DialogContent
                    sx={{
                        flex: '1 1 auto',
                        overflowY: 'auto', // Permite rolagem apenas no conteúdo
                    }}
                >
                    {!isLoading && auditorias.length === 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <Alert severity="info">
                                Não há alterações registradas
                            </Alert>
                        </Box>
                    )}
                    {!isLoading && getCorpoAuditorias()}
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <MoonLoader
                                color="#465f75"
                                loading={isLoading}
                                size={50}
                            />
                        </Box>
                    )}
                </DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Box>
            </Box>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
