import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Container, Divider, Grid } from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DividerComp from '../../../Components/Divider/DividerComp';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { useFetchUseCase } from '../../../hooks/useFetchUseCase';
import { FrontEndPathResources } from '../../../Models/Api';
import { EStatusDoubleCheck } from '../../DoubleCheck/entities/EStatusDoubleCheck';
import { IOrdemColetaLancamento } from '../domain/dtos/IOrdemColetaLancamento';
import { IOrdemColetaResponseDto } from '../domain/dtos/IOrdemColetaResponseDto';
import { ETransgenia } from '../domain/entities/ETransgenia';
import { GetLancarDadosEmbarqueById } from '../domain/usecases/GetLancarDadosEmbarqueById';
import { GetOrdemColetaById } from '../domain/usecases/GetOrdemColetaById';
import { PostLancarDadosEmbarque } from '../domain/usecases/PostLancarDadosEmbarque';
import ClassificacaoForm from './components/ClassificacaoDadosLancamentoForm';
import { EnviarLaudoInput } from './components/EnviarLaudoInput';
import { EnviarTicketCaminhaoCarregadoInput } from './components/EnviarTicketCaminhaoCarregadoInput';
import { LancarDadosEmbarqueForm } from './components/LancarDadosEmbarqueForm';
import {
    validacaoComNota,
    validacaoSemNota,
} from './components/LancarDadosEmbarqueForm/JoiValidators';
import ModalConfirmarEnvioMaxys from './components/ModalConfirmarEnvioMaxys';
import { NotaFiscalInput } from './components/NotaFiscalInput';
import CardNotaFiscal from './components/NotaFiscalInput/components/CardNotaFiscal';
import ObservacoesForm from './components/ObservacaoForm';
import { styles } from './components/OrdemColetaForm/Styles';
import { ApiError } from './ValidarDescarga';
import { ILoteEmbarqueResponseDto } from '../../LoteEmbarque/dtos/ILoteEmbarqueResponseDto';
import { GetLoteEmbarqueByLoteEmbarqueTransportadoraId } from '../../LoteEmbarque/domain/usecases/GetLoteEmbarqueByLoteEmbarqueTransportadoraId';
import { EStatusOrdemColeta } from '../domain/entities/EStatusOrdemColeta';

interface Props {
    postLancarDadosEmbarque: PostLancarDadosEmbarque;
    getLancarDadosEmbarqueById: GetLancarDadosEmbarqueById;
    getOrdemColetaById: GetOrdemColetaById;
    getLoteEmbarqueByLoteEmbarqueTransportadoraId: GetLoteEmbarqueByLoteEmbarqueTransportadoraId;
}

export default function LancarDadosEmbarque({
    postLancarDadosEmbarque,
    getLancarDadosEmbarqueById,
    getOrdemColetaById,
    getLoteEmbarqueByLoteEmbarqueTransportadoraId
}: Props) {
    const navigate = useNavigate();
    const { executePromise, loading } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { id, loteEmbarqueTransportadoraId } = useParams<{ id: string, loteEmbarqueTransportadoraId: string }>();

    const location = useLocation();
    const [state] = useState(location.state as any);

    const [ordemColeta, setOrdemColeta] = useState<IOrdemColetaResponseDto>();
    const [loteEmbarque, setLoteEmbarque] = useState<ILoteEmbarqueResponseDto>();

    const [openModalValidarEnvioErp, setOpenModalValidarEnvioErp] =
        useState<boolean>(false);

    const validarModalEnvioErp =
        state.tipoLoteEmbarque === 'Armazem' && !state.veiculoTerceiro;

    const trocaNotaEstadoDoGoias = state.trocaNotaGo;

    const formLancarDadosEmbarque = useForm<IOrdemColetaLancamento>({
        criteriaMode: 'all',
        resolver: joiResolver(
            ordemColeta?.tipoLoteEmbarque === 'Troca de Nota' && !trocaNotaEstadoDoGoias
                ? validacaoComNota
                : validacaoSemNota
        ),
    });

    const { handleSubmit, reset, setValue } = formLancarDadosEmbarque;
    const onSubmit = handleSubmit((data: IOrdemColetaLancamento) => {
        //#início - Verificar duplicidade de arquivo das notas antes de prosseguir
        if (data.notasAnteriores != null && data.notasAnteriores.length > 1) {
            const arquivosVistos = new Set<any>();
            const hasDuplicatePdf = data.notasAnteriores.some((nota) => {
                if (nota.danfe?.stringBase64) {
                    if (arquivosVistos.has(nota.danfe?.stringBase64)) {
                        return true;
                    }
                    arquivosVistos.add(nota.danfe?.stringBase64);
                }
                return false;
            });

            if (hasDuplicatePdf) {
                enqueueSnackbar('Erro: O mesmo arquivo não pode ser carregado para mais de uma nota.', {
                    variant: 'error',
                    onClick: () => closeSnackbar(),
                });
                return;
            }
        }
        //#fim - Verificar duplicidade de arquivo das notas antes de prosseguir
        executePromise(
            () =>
                postLancarDadosEmbarque.execute({
                    ...data,
                    ordemColetaId: Number(id),
                    classificacao: {
                        ...data.classificacao,
                        transgenia: ETransgenia.EMPTY,
                        ordemColetaId: Number(id),
                    },
                    observacoes: [
                        ...(data.observacoes != null
                            ? data.observacoes.map((obs) => ({
                                  ...obs,
                                  id: obs.id === 0 ? null : obs.id,
                                  dataHoraCriacao: /^\d{2}\/\d{2}\/\d{4}$/.test(
                                      obs.dataHoraCriacao
                                  )
                                      ? null
                                      : obs.dataHoraCriacao,
                              }))
                            : []),
                    ],
                    statusDoubleCheck:
                        formLancarDadosEmbarque.getValues(
                            'statusDoubleCheck'
                        ) === EStatusDoubleCheck.PENDENTE
                            ? EStatusDoubleCheck.RESPONDIDO
                            : null,
                    notasAnteriores: (data?.notasAnteriores && data.notasAnteriores.length > 0)
                        ? data.notasAnteriores.map((nota) => ({
                            ...nota,
                            valorTotal: nota.valorTotal,
                            }))
                        : (trocaNotaEstadoDoGoias ? [] : undefined),
                }),
            () => {
                enqueueSnackbar('Salvo com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        navigate(
                            FrontEndPathResources.LoteEmbarquePorTransportadora
                        );
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.LoteEmbarquePorTransportadora);
            },
            (error) => {
                error.forEach((value: ApiError) => {
                    enqueueSnackbar('Erro: ' + value.mensagem, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                });
            }
        );
    });

    useEffect(() => {
        executePromise(
            () => getLancarDadosEmbarqueById.execute(Number(id)),
            (response: IOrdemColetaLancamento) => {
                const notasAnteriores = response?.notasAnteriores
                    ? response?.notasAnteriores.map((nota) => {
                          nota.dataEmissao = format(
                              new Date(nota.dataEmissao),
                              't'
                          );
                          return nota;
                      })
                    : [];
                if (response.observacoes) {
                    response.observacoes.map((obs) => {
                        obs.dataHoraCriacao = format(
                            new Date(obs.dataHoraCriacao),
                            'dd/MM/Y'
                        );
                        return obs;
                    });
                } else {
                    response.observacoes = [];
                }
                reset({ ...response, notasAnteriores });
            },
            () => {
                setValue(
                    'classificacao.classificacaoAmostras',
                    state.classificacaoAmostras
                );
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLancarDadosEmbarqueById]);

    useEffect(() => {
        if (ordemColeta == null) return;
        
        const permiteStatusAgendado = ordemColeta.statusOrdemColeta !== EStatusOrdemColeta.AGENDADO;
        const permiteStatusPendenteDeFaturamento = ordemColeta.statusOrdemColeta !== EStatusOrdemColeta.PENDENTE_DE_FATURAMENTO;
        const permiteStatusErro = ordemColeta.statusOrdemColeta !== EStatusOrdemColeta.COM_ERRO;

        if (permiteStatusAgendado && permiteStatusErro && permiteStatusPendenteDeFaturamento) navigate(FrontEndPathResources.LoteEmbarquePorTransportadora);
        
    },[ordemColeta])

    useEffect(() => {
        executePromise(
            () => getOrdemColetaById.execute(Number(id)),
            (response: IOrdemColetaResponseDto) => {
                setOrdemColeta(response);
            },
            () => {
                console.log('erro getOrdemColetaById');
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOrdemColetaById]);

    useEffect(() => {
        executePromise(
            () => getLoteEmbarqueByLoteEmbarqueTransportadoraId.execute(Number(loteEmbarqueTransportadoraId)),
            (response: ILoteEmbarqueResponseDto) => {
                setLoteEmbarque(response);
            },
            () => {
                console.error('erro getLoteEmbarqueByLoteEmbarqueTransportadoraId');
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLoteEmbarqueByLoteEmbarqueTransportadoraId]);

    return (
        <SpinnerLoading isLoading={loading}>
            <Container>
                <FormProvider {...formLancarDadosEmbarque}>
                    <PageTitle title={'Lançar dados de embarque'} />
                    <Divider />
                    <LancarDadosEmbarqueForm />
                    <DividerComp texto="Classificação dos dados de lançamento" />
                    <ClassificacaoForm />
                    <DividerComp texto="Documentos anexos" />
                    <EnviarLaudoInput />
                    <EnviarTicketCaminhaoCarregadoInput />
                    {ordemColeta?.tipoLoteEmbarque === 'Troca de Nota' && (
                        <>
                            <DividerComp texto="Nota fiscal" />
                            <NotaFiscalInput {...trocaNotaEstadoDoGoias} />
                            {formLancarDadosEmbarque.formState.errors.notasAnteriores && (
                                <span style={styles().mensagemErrorStyle}>
                                    Necessário adicionar nota
                                </span>
                            )}
                            <DividerComp texto="Notas fiscais adicionadas" />
                            <CardNotaFiscal />
                        </>
                    )}
                    <ObservacoesForm />
                    <Grid container spacing={2} justifyContent="end">
                        <Button
                            onClick={() => {
                                validarModalEnvioErp
                                    ? setOpenModalValidarEnvioErp(true)
                                    : onSubmit();
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    </Grid>
                </FormProvider>
            </Container>
            {openModalValidarEnvioErp && (
                <ModalConfirmarEnvioMaxys
                    open={openModalValidarEnvioErp}
                    onClose={() => setOpenModalValidarEnvioErp(false)}
                    onConfirm={() => {
                        setOpenModalValidarEnvioErp(false);
                        onSubmit();
                    }}
                />
            )}
        </SpinnerLoading>
    );
}
